import * as React from 'react';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import { Datagrid } from '../utilities/Datagrid';
import { Module, ModulePage } from '../interfaces/Interfaces';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import DeleteIcon from '@mui/icons-material/Delete';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { CustomDetailPanelToggle } from './CustomDetailPanelToggle';

interface Props {
    handleToggleCreateModulePage: (edit?: boolean, newType?: string) => void;
    module: Module;
    setPages: (pages: Array<ModulePage>) => void;
    pages: Array<ModulePage>;
    toggleEditPage: (page: ModulePage) => void;
    getModulePages: (moduleId: number) => Promise<Array<ModulePage>>;
    toggleDeletePageModal: (id: number, refresh?: boolean) => void;
}

const ModulePageEditor = ({
    handleToggleCreateModulePage,
    module,
    pages,
    setPages,
    getModulePages,
    toggleEditPage,
    toggleDeletePageModal,
}: Props) => {
    const sortPages = (a: ModulePage, b: ModulePage) => {
        const itemA = Number(a?.pageNumber || 1);
        const itemB = Number(b?.pageNumber || 1);

        return itemA - itemB;
    };

    React.useEffect(() => {
        getModulePages(module?.id).then((res) => {
            setPages(res.sort(sortPages));
        });
    }, [module?.id]);

    const getDetailPanelHeight = React.useCallback(() => 50, []);

    return (
        <Box>
            {pages?.length ? (
                <Datagrid
                    sx={{ padding: '10px' }}
                    getDetailPanelHeight={getDetailPanelHeight}
                    columns={[
                        {
                            headerName: 'Pages',
                            type: 'string',
                            flex: 0.25,
                            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                            renderCell: (params) => (
                                <CustomDetailPanelToggle
                                    id={params.id}
                                    value={params.value}
                                />
                            ),
                        },
                        {
                            field: 'pageNumber',
                            headerName: 'Page Number',
                            type: 'number',
                            flex: 0.25,
                        },
                        {
                            field: 'title',
                            headerName: 'Title',
                            type: 'string',
                            flex: 1,
                        },
                        {
                            field: 'action',
                            headerName: 'Action',
                            flex: 0.5,
                            sortable: false,
                            renderCell: (params) => (
                                <>
                                    <IconButton
                                        sx={{
                                            marginLeft: '5px',
                                            backgroundColor: '#4169E1',
                                            border: 'solid #fff 1px',
                                            color: '#fff',
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                        }}
                                        title={'Duplicate Page'}
                                        onClick={() => {
                                            toggleEditPage(params?.row);
                                            handleToggleCreateModulePage(true);
                                        }}
                                    >
                                        <FileCopyIcon />
                                    </IconButton>
                                    <IconButton
                                        sx={{
                                            marginLeft: '5px',
                                            backgroundColor: '#f1c40f',
                                            border: 'solid #fff 1px',
                                            color: '#fff',
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                        }}
                                        title={'Edit Page'}
                                        onClick={() => {
                                            toggleEditPage(params?.row);
                                        }}
                                    >
                                        <SettingsApplicationsIcon />
                                    </IconButton>
                                    <IconButton
                                        sx={{
                                            marginLeft: '5px',
                                            backgroundColor: '#e74c3c',
                                            border: 'solid #fff 1px',
                                            color: '#fff',
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                        }}
                                        title={'Delete Page'}
                                        onClick={() =>
                                            toggleDeletePageModal(
                                                params?.row?.id,
                                                true,
                                            )
                                        }
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </>
                            ),
                        },
                    ]}
                    data={pages || []}
                />
            ) : (
                <></>
            )}
        </Box>
    );
};

export { ModulePageEditor };
