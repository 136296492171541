import * as React from 'react';
import Box from '@mui/material/Box';
import { Card, Grid, IconButton, Typography } from '@mui/material';
import { Equipment, Module, ModulePage } from '../interfaces/Interfaces';
import Slider from 'react-slick';
import { PagePreviewForCarousel } from './PagePreviewForCarousel';
import { AuthPost } from '../utilities/Routing/Requests';
import { useGlobalStore } from '../utilities/GlobalState';
import { toast } from 'react-toastify';
import Overlay from '../utilities/Overlay/Overlay';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { equipmentStringToRender } from '../utilities/Utilites';

export interface Props {
    module?: Module;
    toggleModuleDetails: () => void;
    addModuleButton?: (modID: number) => any;
    docManagement?: boolean;
    equipmentList: Array<Equipment>;
}

export const ModulePreviewDetails = ({
    module,
    addModuleButton,
    toggleModuleDetails,
    docManagement,
    equipmentList,
}: Props) => {
    const globalState = useGlobalStore((state) => state);
    const settings = {
        dots: true,
        adaptiveHeight: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
    };

    const [pages, setPages] = React.useState<Array<ModulePage>>();
    const [docs, setDocs] = React.useState<Array<any>>();

    const getModulePages = async (moduleId: number) => {
        let data: Array<any> = [];
        return AuthPost(
            '/organization/modulepages',
            { moduleId: moduleId },
            {
                'x-access-token': globalState.authToken,
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200) {
                    data = res?.data;
                    setPages(
                        data?.sort(
                            (a: any, b: any) =>
                                Number(a.pageNumber) - Number(b.pageNumber),
                        ),
                    );
                    return res.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    const getModuleDocuments = async (moduleId: number) => {
        return AuthPost(
            '/documents/getmoduledocuments',
            { moduleID: moduleId?.toString(), type: 'application/pdf' },
            {
                'x-access-token': globalState.authToken,
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200) {
                    setDocs(res?.data);
                    return res.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    React.useEffect(() => {
        if (module) {
            getModulePages(module.id);
            getModuleDocuments(module.id);
        }
    }, [module]);
    return (
        <>
            <Card
                sx={{
                    width: '99%',
                    margin: '10px',
                    outline: 'none',
                    overflow: 'auto',
                }}
            >
                <Grid container>
                    <Grid
                        md={4}
                        style={{
                            alignContent: 'space-around',
                            borderBottom: '2px solid grey',
                        }}
                    >
                        <IconButton onClick={toggleModuleDetails}>
                            <ArrowBackIcon />
                        </IconButton>
                    </Grid>
                    <Grid md={4}>
                        <Typography
                            variant="h6"
                            component="h6"
                            sx={{
                                borderBottom: '2px solid grey',
                                paddingBottom: '8px',
                                paddingTop: '8px',
                                textAlign: 'center',
                            }}
                        >
                            {module?.code + ': ' + module?.title}
                        </Typography>
                    </Grid>
                    <Grid sx={{ borderBottom: '2px solid grey' }} md={4}></Grid>
                </Grid>
                <Box
                    sx={{
                        margin: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Grid
                        container
                        style={{
                            padding: '10px 10px 10px 10px',
                        }}
                    >
                        {addModuleButton && module ? (
                            <Grid container direction="row">
                                <Grid xs={12} sx={{ padding: '5px' }}>
                                    {addModuleButton(module.id)}
                                </Grid>
                            </Grid>
                        ) : (
                            <></>
                        )}
                        <Grid container direction="row">
                            <Grid xs={6} sx={{ padding: '5px' }}>
                                {module?.image?.length ? (
                                    <div
                                        className="Module-Overview-Details"
                                        style={{
                                            // margin: '10px',
                                            textAlign: 'center',
                                            alignContent: 'center',
                                        }}
                                    >
                                        <img
                                            style={{ width: '50%' }}
                                            src={module?.image || ''}
                                            alt="Module Details Missing Image"
                                        />
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                            <Grid xs={6} sx={{ padding: '5px' }}>
                                <div className="Module-Overview-Details">
                                    <Typography
                                        variant="h6"
                                        component="h6"
                                        sx={{
                                            borderBottom: '2px solid grey',
                                            // paddingBottom: '8px',
                                            paddingTop: '8px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <div style={{ marginLeft: '10px' }}>
                                            Duration
                                        </div>
                                    </Typography>
                                    <div style={{ marginLeft: '10px' }}>
                                        {module?.duration}
                                    </div>

                                    <Typography
                                        variant="h6"
                                        component="h6"
                                        sx={{
                                            borderBottom: '2px solid grey',
                                            paddingTop: '8px',
                                            // paddingBottom: '8px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <div style={{ marginLeft: '10px' }}>
                                            Concepts
                                        </div>
                                    </Typography>
                                    <div style={{ marginLeft: '10px' }}>
                                        <ul>
                                            {module?.concepts
                                                ?.split(';')
                                                .map((el, i) => (
                                                    <li
                                                        style={{
                                                            marginBottom: '8px',
                                                        }}
                                                        key={i}
                                                    >
                                                        {el}
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>

                                    <Typography
                                        variant="h6"
                                        component="h6"
                                        sx={{
                                            borderBottom: '2px solid grey',
                                            // paddingBottom: '8px',
                                            paddingTop: '8px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <div style={{ marginLeft: '10px' }}>
                                            Outcomes
                                        </div>
                                    </Typography>
                                    <div
                                        style={{
                                            marginLeft: '10px',
                                            marginBottom: '16px',
                                        }}
                                    >
                                        {module?.outcomes?.length
                                            ? JSON.parse(module?.outcomes)?.map(
                                                  (cert: any, i: number) => {
                                                      if (
                                                          module
                                                              ?.outcomesDetails
                                                              ?.length
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      'outcomes' +
                                                                      i
                                                                  }
                                                              >
                                                                  <div
                                                                      title={
                                                                          module?.outcomesDetails
                                                                              ? JSON.parse(
                                                                                    module?.outcomesDetails,
                                                                                )?.[
                                                                                    i
                                                                                ]
                                                                              : ''
                                                                      }
                                                                      style={{
                                                                          textOverflow:
                                                                              'ellipsis',
                                                                          overflow:
                                                                              'hidden',
                                                                          whiteSpace:
                                                                              'nowrap',
                                                                          marginBottom:
                                                                              '8px',
                                                                      }}
                                                                  >
                                                                      {i +
                                                                          1 +
                                                                          ') '}{' '}
                                                                      <b>
                                                                          {cert}
                                                                      </b>
                                                                      {': '}{' '}
                                                                      {module?.outcomesDetails
                                                                          ? JSON.parse(
                                                                                module?.outcomesDetails,
                                                                            )?.[
                                                                                i
                                                                            ]
                                                                          : ''}
                                                                  </div>
                                                              </div>
                                                          );
                                                      }
                                                      return <></>;
                                                  },
                                              )
                                            : ''}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container direction="row">
                            <Grid xs={6} sx={{ padding: '5px' }}>
                                <div className="Module-Overview-Details">
                                    <Typography
                                        variant="h6"
                                        component="h6"
                                        sx={{
                                            borderBottom: '2px solid grey',
                                            // paddingBottom: '8px',
                                            paddingTop: '8px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <div style={{ marginLeft: '10px' }}>
                                            Description
                                        </div>
                                    </Typography>
                                    <div style={{ marginLeft: '10px' }}>
                                        {module?.description}
                                    </div>

                                    <Typography
                                        variant="h6"
                                        component="h6"
                                        sx={{
                                            borderBottom: '2px solid grey',
                                            // paddingBottom: '8px',
                                            paddingTop: '8px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <div style={{ marginLeft: '10px' }}>
                                            Summary
                                        </div>
                                    </Typography>
                                    <div style={{ marginLeft: '10px' }}>
                                        {module?.summary}
                                    </div>

                                    <Typography
                                        variant="h6"
                                        component="h6"
                                        sx={{
                                            borderBottom: '2px solid grey',
                                            // paddingBottom: '8px',
                                            paddingTop: '8px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <div style={{ marginLeft: '10px' }}>
                                            Instructor Notes
                                        </div>
                                    </Typography>
                                    <div
                                        style={{
                                            marginLeft: '10px',
                                            marginBottom: '16px',
                                        }}
                                    >
                                        {module?.instructorNotes}
                                    </div>
                                </div>
                            </Grid>
                            <Grid xs={6} sx={{ padding: '5px' }}>
                                <div className="Module-Overview-Details">
                                    <Typography
                                        variant="h6"
                                        component="h6"
                                        sx={{
                                            borderBottom: '2px solid grey',
                                            // paddingBottom: '8px',
                                            paddingTop: '8px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <div style={{ marginLeft: '10px' }}>
                                            Equipment
                                        </div>
                                    </Typography>
                                    <div style={{ marginLeft: '10px' }}>
                                        {module?.equipment?.length
                                            ? equipmentStringToRender(
                                                  module?.equipment,
                                                  equipmentList,
                                              )
                                            : ''}
                                    </div>

                                    <Typography
                                        variant="h6"
                                        component="h6"
                                        sx={{
                                            borderBottom: '2px solid grey',
                                            // paddingBottom: '8px',
                                            paddingTop: '8px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <div style={{ marginLeft: '10px' }}>
                                            Certifications
                                        </div>
                                    </Typography>
                                    <div
                                        style={{
                                            marginLeft: '10px',
                                            marginBottom: '16px',
                                        }}
                                    >
                                        {module?.certifications?.length
                                            ? JSON.parse(
                                                  module?.certifications,
                                              )?.map((cert: any, i: number) => {
                                                  if (
                                                      module
                                                          ?.certificationsDetails
                                                          ?.length
                                                  ) {
                                                      return (
                                                          <div
                                                              key={
                                                                  'certifications' +
                                                                  i
                                                              }
                                                          >
                                                              <div
                                                                  title={
                                                                      module?.outcomesDetails
                                                                          ? JSON.parse(
                                                                                module?.outcomesDetails,
                                                                            )?.[
                                                                                i
                                                                            ]
                                                                          : ''
                                                                  }
                                                                  style={{
                                                                      textOverflow:
                                                                          'ellipsis',
                                                                      overflow:
                                                                          'hidden',
                                                                      whiteSpace:
                                                                          'nowrap',
                                                                      marginBottom:
                                                                          '8px',
                                                                  }}
                                                              >
                                                                  {i + 1 + ') '}{' '}
                                                                  <b>{cert}</b>
                                                                  {': '}{' '}
                                                                  {JSON.parse(
                                                                      module?.certificationsDetails,
                                                                  )?.[i] || ''}
                                                              </div>
                                                          </div>
                                                      );
                                                  }
                                                  return <></>;
                                              })
                                            : ''}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        {docs?.length ? (
                            <Grid container direction="row">
                                <Grid xs={12} sx={{ padding: '5px' }}>
                                    <div className="Module-Overview-Details">
                                        <Typography
                                            variant="h6"
                                            component="h6"
                                            sx={{
                                                borderBottom: '2px solid grey',
                                                // paddingBottom: '8px',
                                                paddingTop: '8px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            <div style={{ marginLeft: '10px' }}>
                                                Supporting Documents
                                            </div>
                                        </Typography>

                                        <div
                                            style={{
                                                marginLeft: '10px',
                                                marginBottom: '16px',
                                            }}
                                        >
                                            {docs?.map((doc) => {
                                                return (
                                                    <iframe
                                                        key={doc?.id}
                                                        width={
                                                            // docManagement
                                                            //     ? '99%'
                                                            //     : '150'
                                                            '150'
                                                        }
                                                        height={
                                                            docManagement
                                                                ? '500'
                                                                : '100'
                                                        }
                                                        src={
                                                            docManagement
                                                                ? doc?.link
                                                                : doc?.link +
                                                                  '#toolbar=0'
                                                        }
                                                    ></iframe>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        ) : (
                            <></>
                        )}
                        <Grid
                            container
                            direction="row"
                            sx={{
                                marginTop: '5px',
                                padding: '10px',
                                borderRadius: '20px',
                                boxShadow:
                                    'rgb(0, 0, 0) 0px 2px 11px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
                            }}
                        >
                            <Grid
                                xs={12}
                                sx={{ width: '95%', minHeight: '441px' }}
                            >
                                {pages?.length ? (
                                    <div
                                        style={{
                                            width: '95%',
                                            margin: 'auto',
                                            marginTop: '20px',
                                            marginBottom: '20px',
                                        }}
                                        className="slider-container"
                                    >
                                        <Slider {...settings}>
                                            {pages?.map((page, i) => (
                                                <div
                                                    key={
                                                        'page-preview-' +
                                                        page.id
                                                    }
                                                >
                                                    {i === 0 ? (
                                                        <Overlay show={true} />
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <Card>
                                                        <h3
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                            }}
                                                        >
                                                            Page{' '}
                                                            {page.pageNumber}{' '}
                                                            Preview
                                                        </h3>
                                                        <PagePreviewForCarousel
                                                            page={page}
                                                            currentPage={
                                                                page.id
                                                            }
                                                            classID={1}
                                                        />
                                                    </Card>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        </>
    );
};
