import { ThemeProvider } from '@mui/material/styles';
import { defaultTheme, toggleActiveUser } from '../utilities/Utilites';
import * as React from 'react';
import Box from '@mui/system/Box';
import { ApiOrganization, OrganizationUser } from '../interfaces/Interfaces';
import { Card, Grid, IconButton } from '@mui/material';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { AuthGet, AuthPost } from '../utilities/Routing/Requests';
import { Datagrid } from '../utilities/Datagrid';
import GroupsIcon from '@mui/icons-material/Groups';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { useGlobalStore } from '../utilities/GlobalState';
import { AxiosResponse } from 'axios';
import { CreateEditStudentModal } from '../classes/CreateEditStudentModal';
import { AddInstructorModal } from '../organizations/AddInstructorModal';
import { StudentManagementModal } from '../classes/StudentManagementModal';
import { EditInstructorModal } from './EditInstructorModal';
import { toast } from 'react-toastify';

export const Instructors = () => {
    const [studentManagementModalIsOpen, setStudentManagementModalIsOpen] =
        React.useState(false);
    const [addInstructorModalIsOpen, setAddInstructorModalIsOpen] =
        React.useState(false);
    const [selectedStudents, setSelectedStudents] = React.useState<
        Array<OrganizationUser> | undefined
    >(undefined);
    const [selectedInstructor, setSelectedInstructor] =
        React.useState<OrganizationUser | null>(null);
    const [editInstructorModalIsOpen, setEditInstructorModalIsOpen] =
        React.useState(false);
    const globalState = useGlobalStore((state) => state);
    const [organizations, setOrganizations] = React.useState<
        Array<ApiOrganization>
    >([]);
    const [selectedStudent, setSelectedStudent] =
        React.useState<OrganizationUser | null>(null);
    const [editStudentModalIsOpen, setEditStudentModalIsOpen] =
        React.useState(false);

    let instructors = organizations[0]?.users?.length
        ? organizations[0]?.users?.filter((user) => {
              return user?.type?.includes('Instructor');
          })
        : [];

    if (globalState?.user?.type?.includes('APTAdmin')) {
        const allInstructors: Array<OrganizationUser> = [];
        organizations?.forEach((org) => {
            org?.users
                ?.filter((user) => {
                    return user?.type?.includes('Instructor');
                })
                .forEach((user) => {
                    allInstructors.push(user);
                });
        });
        instructors = allInstructors;
    }

    const toggleAddInstuctorModal = () => {
        const current = addInstructorModalIsOpen;
        setAddInstructorModalIsOpen(!current);
    };

    const columnVisibilityModel = React.useMemo(() => {
        if (
            globalState?.user?.type?.includes('APTAdmin') ||
            globalState?.user?.type?.includes('OrganizationAdmin')
        ) {
            return {
                firstName: true,
                lastName: true,
                userEmail: true,
                active: true,
                action: true,
                id: true,
            };
        }
        return {
            firstName: true,
            lastName: true,
            userEmail: true,
            active: false,
            action: true,
            id: true,
        };
    }, [globalState?.user?.type]);

    const toggleStudentManagementModal = (
        students: Array<OrganizationUser> | null,
        professor: OrganizationUser | null,
    ) => {
        const current = studentManagementModalIsOpen;
        if (students) {
            setSelectedStudents(students);
        }
        setSelectedInstructor(professor);
        setStudentManagementModalIsOpen(!current);
    };

    const toggleEditInstructorModal = (
        instructor?: OrganizationUser | null,
    ) => {
        const current = editInstructorModalIsOpen;
        if (instructor) {
            setSelectedInstructor(instructor);
        }
        setEditInstructorModalIsOpen(!current);
    };

    const toggleEditStudentModal = (student: OrganizationUser | null) => {
        const current = editStudentModalIsOpen;
        setSelectedStudent(student);
        setEditStudentModalIsOpen(!current);
    };

    const getOrganizations = () => {
        AuthGet('/organization/orglist', {
            'x-access-token': globalState?.authToken || '',
            canCache: true,
        })
            .then((res: AxiosResponse<Array<ApiOrganization>>) => {
                const orgList = globalState?.user?.type?.includes('APTAdmin')
                    ? res?.data
                    : res?.data?.filter((orgs) => {
                          return (
                              orgs?.id?.toString() === globalState?.user?.orgId
                          );
                      });

                setOrganizations(orgList);
                return orgList;
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    const getStudentManagementData = (instructor: OrganizationUser) => {
        AuthPost(
            '/userinstructor/getinstructorusers',
            {
                instructorID: instructor.id,
            },
            {
                'x-access-token': globalState?.authToken,
            },
        )
            .then((userRes) => {
                if (
                    userRes?.status === 200 ||
                    userRes?.status === 201 ||
                    userRes?.status === 204
                ) {
                    return userRes;
                }
            })
            .then((students) => {
                return students?.data?.map((studs: any) => studs?.userID);
            })
            .then((studentArray) => {
                const studentObjects: Array<any> = [];
                studentArray?.forEach((stud: any) => {
                    const addStu = organizations[0]?.users?.filter((user) => {
                        return user?.id === stud;
                    })[0];
                    if (addStu) {
                        studentObjects.push(addStu);
                    }
                });
                return toggleStudentManagementModal(studentObjects, instructor);
            })
            .catch((err) => {
                toast.error(err.toString());
                console.log(err);
            });
    };

    React.useEffect(() => {
        getOrganizations();
    }, []);

    return (
        <ThemeProvider theme={defaultTheme}>
            {CreateEditStudentModal({
                open: editStudentModalIsOpen,
                handleClose: () => setEditStudentModalIsOpen(false),
                organization: organizations[0],
                authToken: globalState.authToken,
                selectedInstructor: selectedInstructor,
                selectedStudent: selectedStudent,
            })}
            {
                // selectedStudents &&
                StudentManagementModal({
                    open: studentManagementModalIsOpen,
                    toggleModal: toggleStudentManagementModal,
                    students: selectedStudents,
                    toggleEditStudentModal: toggleEditStudentModal,
                    selectedInstructor: selectedInstructor,
                    authToken: globalState.authToken,
                })
            }
            {AddInstructorModal({
                open: addInstructorModalIsOpen,
                handleClose: () => setAddInstructorModalIsOpen(false),
                organization: organizations[0],
                authToken: globalState.authToken,
            })}
            {selectedInstructor &&
                EditInstructorModal({
                    open: editInstructorModalIsOpen,
                    handleClose: toggleEditInstructorModal,
                    instructor: selectedInstructor,
                    authToken: globalState?.authToken,
                    orgId: selectedInstructor?.orgId || '',
                })}
            <Box
                sx={{
                    paddingTop: '30px',
                }}
            >
                <Card sx={{ margin: '10px' }}>
                    <Grid item xs={12} md={3}>
                        <Grid
                            container
                            style={{
                                padding: '10px 10px 10px 20px',
                                backgroundColor: `${defaultTheme.palette.primary.main}`,
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: '20px',
                            }}
                        >
                            <Grid xs={8} sx={{ alignSelf: 'center' }}>
                                <div
                                // style={{
                                //     fontWeight: 'bold',
                                //     fontSize: '20px',
                                //     padding: '0 10px 0px 20px',
                                //     // backgroundColor: '#f44336',
                                //     color: '#f44336',
                                // }}
                                >
                                    Instructors
                                </div>
                            </Grid>
                            <Grid
                                xs={4}
                                sx={{
                                    textAlignLast: 'right',
                                    paddingRight: '20px',
                                }}
                            >
                                <IconButton
                                    title={'Create Instructor'}
                                    // sx={
                                    //     {
                                    //         // color: 'green',
                                    //         // border: 'solid #fff 1px',
                                    //         // padding: 0,
                                    //         // fontWeight: 'bold',
                                    //         // fontSize: '20px',
                                    //     }
                                    // }
                                    sx={{
                                        color: '#fff',
                                    }}
                                    onClick={toggleAddInstuctorModal}
                                >
                                    <PersonAddAlt1Icon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        {/* <hr style={{ border: '1px solid red' }} /> */}
                        <Datagrid
                            columnVisibilityModel={columnVisibilityModel}
                            sx={{ padding: '10px' }}
                            columns={[
                                {
                                    field: 'id',
                                    headerName: 'ID',
                                    type: 'string',
                                    flex: 0.25,
                                },
                                {
                                    field: 'firstName',
                                    headerName: 'First Name',
                                    type: 'string',
                                    flex: 1,
                                },
                                {
                                    field: 'lastName',
                                    headerName: 'Last Name',
                                    type: 'string',
                                    flex: 1,
                                    // editable: true,
                                },
                                {
                                    field: 'userEmail',
                                    headerName: 'Email',
                                    type: 'string',
                                    flex: 1,
                                    // editable: true,
                                },
                                {
                                    field: 'active',
                                    headerName: 'Active',
                                    type: 'boolean',
                                    flex: 1,
                                    // editable: true,
                                },
                                {
                                    field: 'orgId',
                                    headerName: 'Organization',
                                    flex: 1,
                                    sortable: false,
                                    renderCell: (params) => {
                                        const id = params?.row?.orgId;
                                        const orgName = organizations?.filter(
                                            (org) => {
                                                return (
                                                    org?.id.toString() === id
                                                );
                                            },
                                        )[0]?.name;
                                        return <>{orgName}</>;
                                    },
                                },
                                {
                                    field: 'action',
                                    headerName: 'Action',
                                    flex: 1,
                                    sortable: false,
                                    renderCell: (params) => {
                                        return (
                                            <>
                                                <IconButton
                                                    sx={{
                                                        marginLeft: '5px',
                                                        backgroundColor:
                                                            '#4169E1',
                                                        border: 'solid #fff 1px',
                                                        color: '#fff',
                                                        fontWeight: 'bold',
                                                        fontSize: '20px',
                                                    }}
                                                    title={'Student Management'}
                                                    onClick={() => {
                                                        getStudentManagementData(
                                                            params.row,
                                                        );
                                                    }}
                                                >
                                                    <GroupsIcon />
                                                </IconButton>
                                                <IconButton
                                                    sx={{
                                                        marginLeft: '5px',
                                                        backgroundColor:
                                                            '#f1c40f',
                                                        border: 'solid #fff 1px',
                                                        color: '#fff',
                                                        fontWeight: 'bold',
                                                        fontSize: '20px',
                                                    }}
                                                    title={'Edit Instructor'}
                                                    onClick={() =>
                                                        toggleEditInstructorModal(
                                                            params?.row,
                                                        )
                                                    }
                                                >
                                                    <SettingsApplicationsIcon />
                                                </IconButton>
                                                <IconButton
                                                    sx={{
                                                        marginLeft: '5px',
                                                        backgroundColor:
                                                            '#e74c3c',
                                                        border: 'solid #fff 1px',
                                                        color: '#fff',
                                                        fontWeight: 'bold',
                                                        fontSize: '20px',
                                                    }}
                                                    title={'Delete Instructor'}
                                                    onClick={() => {
                                                        toggleActiveUser(
                                                            false,
                                                            params?.row
                                                                ?.userEmail,
                                                            globalState?.authToken,
                                                        );
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </>
                                        );
                                    },
                                },
                            ]}
                            data={instructors}
                        />
                    </Grid>
                </Card>
            </Box>
        </ThemeProvider>
    );
};
