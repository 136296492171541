import { AxiosResponse } from 'axios';
import { AuthGet, AuthPost } from './Routing/Requests';
import {
    Link,
    TextareaAutosize,
    Typography,
    createTheme,
    styled,
} from '@mui/material';
import * as React from 'react';
import { red } from '@mui/material/colors';
import { DateTime } from 'luxon';
import {
    ApiCustomer,
    ApiUser,
    Equipment,
    MultiSelectOption,
    OrganizationUser,
} from '../interfaces/Interfaces';
import UAParser from 'ua-parser-js';
import { toast } from 'react-toastify';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import BuildIcon from '@mui/icons-material/Build';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import WarningIcon from '@mui/icons-material/Warning';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';

const toggleActiveUser = (
    active: boolean,
    email: string,
    authToken: string,
) => {
    const userBody = {
        email: email,
        active: active,
    };

    AuthPost('/user/active', userBody, {
        'x-access-token': authToken,
    })
        .then(() => {
            return;
        })
        .catch((err) => {
            console.error(err.toString());
            toast.error(err.toString());
        });
};

const editUser = (
    userEmail?: string,
    firstName?: string,
    lastName?: string,
    orgId?: string,
    token?: string,
    type?: string,
    students?: string,
    instructor?: string,
    classes?: string,
    answers?: string,
) => {
    // Check if credentials are valid before using
    if (userEmail && firstName && lastName && orgId && token) {
        AuthPost(
            '/user/edit',
            {
                userEmail,
                firstName,
                lastName,
                orgId,
                type,
                students,
                instructor,
                classes,
                answers,
            },
            {
                'x-access-token': token,
                canCache: true,
            },
        )
            .then((res: AxiosResponse<OrganizationUser>) => {
                if (res?.status === 200 || res?.status === 201) {
                    return res;
                } else {
                    throw new Error(res?.toString() || 'error');
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    } else {
        throw new Error('Invalid or Missing Data');
    }
};

const editUserActive = (email: string, active: boolean, token: string) => {
    // Check if credentials are valid before using
    if (email && active !== null && active !== undefined && token) {
        return AuthPost(
            '/user/active',
            { email, active },
            {
                'x-access-token': token,
                canCache: true,
            },
        )
            .then((res: AxiosResponse<Array<ApiUser>>) => {
                if (res?.status === 200) {
                    return res;
                } else {
                    toast.error(res?.toString());
                    return res;
                }
            })
            .catch((err) => {
                toast.error(err.toString());
                return err;
            });
    } else {
        toast.error('Invalid or Missing Data');
        return new Promise((res) => res('error'));
    }
};

// TODO remove ANY
// eslint-disable-next-line
const Copyright = (props: any) => {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            <Link color="inherit" href="https://industrytrainingacademy.com/">
                Industry Training Academy
            </Link>
            {' © '}
            {DateTime.now().setZone('America/New_York').toFormat('yyyy')}
        </Typography>
    );
};

const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#00aeef',
        },
        secondary: {
            main: red[500],
        },
        warning: {
            main: '#FFEA00',
        },
    },
});

const formatDateNoTime = (date: DateTime) => {
    return date.toFormat('yyyy-MM-dd'); // yyyy-MM-dd
};

const formatDateNoTimeMonth = (date: DateTime) => {
    return date.toFormat('MM-dd-yyyy'); // MM-dd-yyyy
};

const formatTimeNoDate = (date: DateTime) => {
    return date.toFormat('HH:mm:ss'); // HH:mm:ss
};

const formatTimeNoDateIncludeTZ = (date: DateTime) => {
    return date.toFormat('h:mm a ZZZZ'); // h:mm PM EDT (12 hour format)
};

const formatTimeWithSecondsNoDateIncludeTZ = (date: DateTime) => {
    return date.toFormat('h:mm:ss a ZZZZ'); // h:mm:ss PM EDT (12 hour format)
};

const formatDateWithTime = (date: DateTime) => {
    return date.toFormat('yyyy-MM-dd HH:mm:ss'); // yyyy-MM-dd HH:mm:ss
};

const formatDateWithTime2 = (date: DateTime) => {
    return date.toFormat('MM-dd-yyyy HH:mm:ss'); // MM-dd-yyyy HH:mm:ss
};

const formatDateWithTimeAndT = (date: DateTime) => {
    return date.toFormat("yyyy-MM-dd'T'HH:mm:ss"); // yyyy-MM-dd'T'HH:mm:ss
};

const fetchEmployee = (username: string) => {
    return AuthGet('/GetEmployeeUsername', {
        employeeUsername: username,
        canCache: true,
    }).catch((err) => {
        toast.error(err.toString());
        return err;
    });
};

const convertMinutesToHours = (mins: number) => {
    const hours = mins / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    return rhours + ' hrs ' + rminutes + ' mins';
};

const getDateThisWeekStart = () => {
    return DateTime.now()
        .setZone('America/New_York')
        .startOf('week')
        .toFormat('yyyy-MM-dd'); // yyyy-MM-dd
};

const getDateLastWeekStart = () => {
    return DateTime.now()
        .setZone('America/New_York')
        .minus({ days: 7 })
        .startOf('week')
        .toFormat('yyyy-MM-dd'); // yyyy-MM-dd
};

const getDateLastWeekEnd = () => {
    return DateTime.now()
        .setZone('America/New_York')
        .minus({ days: 7 })
        .startOf('week')
        .plus({ days: 6 })
        .toFormat('yyyy-MM-dd'); // yyyy-MM-dd
};

const isAdmin = (roles: string) => roles.indexOf('ADMIN') > -1;

const customerSelectOptionMapper = (customerArr: Array<ApiCustomer>) =>
    customerArr?.map((cx) => {
        return {
            label: cx.name,
            id: cx.customer,
        };
    });

const customerIdStringToSelectOption = (
    customerArr: Array<ApiCustomer>,
    customer: string,
) => {
    const customerFiltered = customerArr?.filter(
        (cx) => cx.customer === customer,
    )[0];
    return {
        label: customerFiltered.name,
        id: customerFiltered.customer,
    };
};

const compareArrays = (
    array1: Array<MultiSelectOption>,
    array2: Array<MultiSelectOption>,
) => {
    const array1sorted = array1.sort((a, b) => Number(a.key) - Number(b.key));
    const array2sorted = array2.sort((a, b) => Number(a.key) - Number(b.key));

    return array1sorted.every((value, index) => {
        return value?.key === array2sorted[index]?.key;
    });
};

const getUserAgentDetails = () => {
    const parser = new UAParser();
    return {
        browser: parser.getBrowser(),
        engine: parser.getEngine(),
        os: parser.getOS(),
        device: parser.getDevice(),
        cpu: parser.getCPU(),
    };
};

const typeSelectOptionMapper = (typesArr: Array<string>) =>
    typesArr?.map((type) => {
        return {
            option: type,
            key: type,
        };
    });

const userSelectOptionMapper = (usersArr: Array<OrganizationUser>) =>
    usersArr?.map((user) => {
        return {
            option:
                user?.firstName +
                    ' ' +
                    user?.lastName +
                    ' ' +
                    user?.userEmail || '',
            key: user?.id || '',
        };
    });

const typeStringToSelectOptionFormatter = (types: string) => {
    if (types) {
        const formattedTypes = types?.split(',');

        return formattedTypes?.map((type) => {
            return {
                option: type,
                key: type,
            };
        });
    }
    return [];
};

const equipmentStringToSelectOptionFormatter = (
    equipmentIDs: string,
    equipmentArr: Array<Equipment>,
) => {
    if (equipmentIDs?.length && equipmentArr?.length) {
        const formattedIDs = equipmentIDs?.split(',');
        return formattedIDs?.map((id: any) => {
            const equipment = equipmentArr?.find(
                (eq) => eq?.id?.toString() === id,
            );
            return {
                option: equipment ? equipment.title : '',
                key: id,
            };
        });
    }
    return [];
};

const equipmentStringToRender = (
    equipmentIDs: string,
    equipmentArr: Array<Equipment>,
) => {
    if (equipmentIDs?.length && equipmentArr?.length) {
        const formattedIDs = equipmentIDs?.split(',');
        return formattedIDs?.map((id: any, i: number) => {
            const equipment = equipmentArr?.find(
                (eq) => eq?.id?.toString() === id,
            );
            return (
                <div key={'equipment' + i}>
                    <div
                        title={equipment?.description || ''}
                        style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            marginBottom: '8px',
                        }}
                    >
                        {i + 1 + ') '} <b>{equipment?.title}</b>
                        {': '} {equipment?.description || ''}
                    </div>
                </div>
            );
        });
    }
    return [];
};

const equipmentSelectOptionMapper = (equipmentArr: Array<Equipment>) =>
    equipmentArr?.map((equipment) => {
        return {
            option: equipment?.title || '',
            key: equipment?.id || '',
        };
    });

const equipmentSelectOptionsToStringOfIDs = (
    equipmentArr: Array<MultiSelectOption>,
) => {
    let equipmentIDs = '';
    equipmentArr?.forEach((equipment, i) => {
        if (equipmentArr?.length - 1 === i) {
            equipmentIDs = equipmentIDs + equipment.key;
        } else if (!equipmentIDs?.length) {
            equipmentIDs = equipment.key + ',';
        } else {
            equipmentIDs = equipmentIDs + equipment.key + ',';
        }
    });
    return equipmentIDs;
};

const createBulletedList = (str: string) => {
    const items = str.split(',');
    return (
        <ul>
            {items?.map((item: string, index: number) => {
                return (
                    <li
                        style={{ fontSize: '16px' }}
                        key={'change-notes-' + index}
                    >
                        <p>{item.trim()}</p>
                    </li>
                );
            })}
        </ul>
    );
};

const generatePassword = () => {
    const length = 8;
    const charset =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
};

const userTypes = ['Student', 'Instructor', 'OrganizationAdmin', 'APTAdmin'];
const headerIcons = [
    'Lightbulb',
    'Book',
    'Pencil',
    'Wrench',
    'Headphones',
    'Warning',
    'Robot',
];

const getHeaderIcon = (type?: string, size?: string) => {
    switch (type) {
        case 'Lightbulb':
            return (
                <div className="Module-Header-Icon-Background-BR">
                    <EmojiObjectsIcon
                        sx={
                            size
                                ? { height: size, width: size, padding: '6px' }
                                : { padding: '6px' }
                        }
                        fontSize="large"
                        htmlColor="white"
                    />
                </div>
            );
        case 'Pencil':
            return (
                <div className="Module-Header-Icon-Background-RB">
                    <BorderColorIcon
                        fontSize="large"
                        htmlColor="white"
                        sx={
                            size
                                ? { height: size, width: size, padding: '6px' }
                                : { padding: '6px' }
                        }
                    />
                </div>
            );
        case 'Book':
            return (
                <div className="Module-Header-Icon-Background-RB">
                    <ImportContactsIcon
                        fontSize="large"
                        htmlColor="white"
                        sx={
                            size
                                ? { height: size, width: size, padding: '6px' }
                                : { padding: '6px' }
                        }
                    />
                </div>
            );
        case 'Robot':
            return (
                <div className="Module-Header-Icon-Background-OB">
                    <PrecisionManufacturingIcon
                        fontSize="large"
                        htmlColor="white"
                        sx={
                            size
                                ? { height: size, width: size, padding: '6px' }
                                : { padding: '6px' }
                        }
                    />
                </div>
            );
        case 'Wrench':
            return (
                <div className="Module-Header-Icon-Background-BR">
                    <BuildIcon
                        fontSize="large"
                        htmlColor="white"
                        sx={
                            size
                                ? { height: size, width: size, padding: '6px' }
                                : { padding: '6px' }
                        }
                    />
                </div>
            );
        case 'Headphones':
            return (
                <div className="Module-Header-Icon-Background-RB">
                    <HeadphonesIcon
                        fontSize="large"
                        htmlColor="white"
                        sx={
                            size
                                ? { height: size, width: size, padding: '6px' }
                                : { padding: '6px' }
                        }
                    />
                </div>
            );
        case 'Warning':
            return (
                <div className="Module-Header-Icon-Background-OB">
                    <WarningIcon
                        fontSize="large"
                        htmlColor="white"
                        sx={
                            size
                                ? { height: size, width: size, padding: '6px' }
                                : { padding: '6px' }
                        }
                    />
                </div>
            );
        default:
            return (
                <div className="Module-Header-Icon-Background-OB">
                    <PrecisionManufacturingIcon
                        fontSize="large"
                        htmlColor="white"
                        sx={
                            size
                                ? { height: size, width: size, padding: '6px' }
                                : { padding: '6px' }
                        }
                    />
                </div>
            );
    }
};

const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const Textarea = styled(TextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 2px 2px ${
        theme.palette.mode === 'dark' ? grey[900] : grey[50]
    };

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
          theme.palette.mode === 'dark' ? blue[600] : blue[200]
      };
    }

    /* firefox */
    &:focus-visible {
      outline: 0;
    }
  `,
);

const blobToFile = (blob: Blob) =>
    new File([blob], (blob as File)?.name || 'image', {
        type: 'image/png',
        lastModified: Date.now(),
    });

export {
    defaultTheme,
    Copyright,
    formatDateNoTime,
    formatDateWithTime,
    fetchEmployee,
    formatTimeNoDate,
    getDateLastWeekStart,
    getDateLastWeekEnd,
    getDateThisWeekStart,
    convertMinutesToHours,
    formatTimeNoDateIncludeTZ,
    formatDateNoTimeMonth,
    formatDateWithTimeAndT,
    formatTimeWithSecondsNoDateIncludeTZ,
    editUser,
    editUserActive,
    customerSelectOptionMapper,
    compareArrays,
    customerIdStringToSelectOption,
    isAdmin,
    toggleActiveUser,
    getUserAgentDetails,
    formatDateWithTime2,
    typeSelectOptionMapper,
    userTypes,
    headerIcons,
    typeStringToSelectOptionFormatter,
    createBulletedList,
    userSelectOptionMapper,
    generatePassword,
    getHeaderIcon,
    Textarea,
    equipmentSelectOptionMapper,
    equipmentStringToSelectOptionFormatter,
    equipmentSelectOptionsToStringOfIDs,
    equipmentStringToRender,
    blobToFile,
};
