import 'react-csv-importer/dist/index.css';
import React from 'react';
import { Importer, ImporterField } from 'react-csv-importer';
import { Box, Card, Modal } from '@mui/material';
import { AuthPost } from '../Routing/Requests';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import { defaultStyles, FileIcon } from 'react-file-icon';
export interface Props {
    open: boolean | undefined;
    handleClose: (id?: number | undefined) => void;
    orgId: string;
    authToken: string;
}

const csvData = [
    ['firstName', 'lastName', 'userEmail', 'type', 'password'],
    [
        'multitype11@test.com',
        'multitype1@test.com',
        'multitype1@test.com',
        'Student,Instructor,OrganizationAdmin',
        'multitype1@test.com',
    ],
    ['Jane', 'Doe', 'jane21@email.com', 'Student', 'securePassword2!'],
    ['Jack', 'Doe', 'jack11@email.com', 'Student', 'securePassword3!'],
    ['Jill', 'Doe', 'jill11@email.com', 'Student', 'securePassword4!'],
    ['Bill', 'Nye', 'bill21@email.com', 'Instructor', 'securePassword5!'],
];

export const CsvImporter = ({ open, handleClose, orgId, authToken }: Props) => {
    return (
        <Modal
            open={open || false}
            onClose={() => handleClose()}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Card
                sx={{
                    overflow: 'auto',
                    verticalAlign: 'middle',
                    backgroundColor: 'unset',
                    minWidth: '1000px',
                }}
            >
                <Box
                    sx={{
                        // width: '100px',
                        // maxWidth: '600px',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Card
                        sx={{
                            width: '100px',
                            justifySelf: 'center',
                            padding: '10px',
                        }}
                    >
                        <CSVLink filename={'Example.csv'} data={csvData}>
                            Example CSV
                            <FileIcon extension="csv" {...defaultStyles.csv} />
                        </CSVLink>
                    </Card>
                </Box>
                <Importer
                    dataHandler={async (rows) => {
                        AuthPost(
                            '/organization/bulkcreatestudent',
                            {
                                UsersArray: rows,
                                AddToOrg: orgId,
                            },
                            {
                                'x-access-token': authToken,
                            },
                        )
                            .then((userRes) => {
                                if (
                                    userRes?.status === 200 ||
                                    userRes?.status === 201 ||
                                    userRes?.status === 204
                                ) {
                                    toast.success(userRes.data);
                                    handleClose();
                                }
                            })
                            .catch((err) => {
                                toast.error(err.toString());
                            });
                    }}
                    defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
                    restartable={false} // optional, lets user choose to upload another file when import is complete
                    // onStart={({ file, preview, fields, columnFields }) => {
                    //     // optional, invoked when user has mapped columns and started import
                    //     prepMyAppForIncomingData();
                    // }}
                    // onComplete={({ file, preview, fields, columnFields }) => {
                    //     // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                    //     showMyAppToastNotification();
                    // }}
                    // onClose={({ file, preview, fields, columnFields }) => {
                    //     // optional, if this is specified the user will see a "Finish" button after import is done,
                    //     // which will call this when clicked
                    //     goToMyAppNextPage();
                    // }}

                    // CSV options passed directly to PapaParse if specified:
                    // delimiter={...}
                    // newline={...}
                    // quoteChar={...}
                    // escapeChar={...}
                    // comments={...}
                    // skipEmptyLines={...}
                    // delimitersToGuess={...}
                    // chunkSize={...} // defaults to 10000
                    // encoding={...} // defaults to utf-8, see FileReader API
                >
                    <ImporterField name="firstName" label="First Name" />
                    <ImporterField name="lastName" label="Last Name" />
                    <ImporterField name="userEmail" label="Email" />
                    <ImporterField name="type" label="User Type" />
                    <ImporterField name="password" label="Password" />
                </Importer>
            </Card>
        </Modal>
    );
};
