import * as React from 'react';
import Card from '@mui/material/Card';
import { GlobalState, Module } from '../interfaces/Interfaces';
import GradingIcon from '@mui/icons-material/Grading';
import { Grid, Grid2, IconButton } from '@mui/material';
import { useGlobalStore } from '../utilities/GlobalState';
import { AuthPost } from '../utilities/Routing/Requests';
import { toast } from 'react-toastify';

interface Props {
    handleToggleModulePage: () => void;
    module: Module;
    showGradebook?: () => void;
    disabled?: boolean;
    classID?: number;
}

const ModulePreviewCard = ({
    handleToggleModulePage,
    module,
    showGradebook,
    disabled,
    classID,
}: Props) => {
    const globalState = useGlobalStore((state: GlobalState) => state);
    const [countCorrect, setCountCorrect] = React.useState<number>(0);
    const [totalGrades, setTotalGrades] = React.useState<number>();
    React.useEffect(() => {
        setTotalGrades(0);
        let newGrades = totalGrades;
        const countPromise = new Promise((resolve) => {
            return resolve(
                AuthPost(
                    '/assessmentdetail/getAssessmentDetailCountByID',
                    { moduleID: module.id },
                    {
                        'x-access-token': globalState.authToken,
                        canCache: true,
                    },
                )
                    .then((data) => {
                        newGrades = Number(Object.values(data.data)[0]) || 0;
                        setTotalGrades(newGrades);
                        return newGrades;
                    })
                    .catch((err) => {
                        console.error(err.toString());
                        toast.error(err.toString());
                    }),
            );
        });
        countPromise.then(() => {
            if (module && classID && globalState.user?.id) {
                AuthPost(
                    '/gradebook/getusergradebookcorrectcount',
                    {
                        moduleID: module.id,
                        classID: classID,
                        userID: globalState.user.id,
                    },
                    {
                        'x-access-token': globalState.authToken,
                        canCache: true,
                    },
                )
                    .then((data) => {
                        newGrades = Number(Object.values(data.data)) || 0;
                        setCountCorrect(newGrades);
                    })
                    .catch((err) => {
                        console.error(err.toString());
                        toast.error(err.toString());
                    });
            }
            return;
        });
    }, [module]);

    const backImg = module?.image?.length ? module.image : '';
    const getColor = () => {
        return (
            'hsl(' +
            360 * Math.random() +
            ',' +
            (25 + 70 * Math.random()) +
            '%,' +
            75 +
            '%)'
        );
    };
    const handleClick = (e: any) => {
        if (
            (e.target.getAttribute('data-testid') === 'GradingIcon' ||
                e?.target?.id === 'button') &&
            showGradebook
        ) {
            showGradebook();
        } else {
            handleToggleModulePage();
        }
    };

    return (
        <Card
            onClick={handleClick}
            style={{
                boxShadow:
                    '0px 2px 11px -1px rgba(0,0,0,4.8),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                opacity: disabled ? 0.5 : 1,
                pointerEvents: disabled ? 'none' : 'auto',
            }}
            sx={{
                margin: '10px',
                width: '300px',
                height: '300px',
                display: 'flex',
                flexDirection: 'column',
                // backgroundImage: backImg as string,
            }}
            id="parent"
        >
            {showGradebook && globalState.user?.type.includes('Student') && (
                <IconButton
                    id="button"
                    sx={{
                        // position: 'fixed',
                        // marginLeft: '5px',
                        backgroundColor: '#f1c40f',
                        border: 'solid #f1c40f 1px',
                        color: '#fff',
                        fontWeight: 'bold',
                        fontSize: '20px',
                        borderRadius: 'unset',
                    }}
                    title={'Gradebook'}
                >
                    <Grid container id="button">
                        <Grid2
                            id="button"
                            size="grow"
                            style={{
                                textAlign: 'left',
                                alignContent: 'center',
                            }}
                        >
                            <GradingIcon />
                        </Grid2>
                        <Grid2
                            id="button"
                            style={{
                                textAlign: 'center',
                                alignContent: 'center',
                            }}
                            size={6}
                        >
                            Gradebook
                        </Grid2>
                        <Grid2
                            id="button"
                            size="grow"
                            style={{
                                textAlign: 'right',
                                alignContent: 'center',
                            }}
                        >
                            {`${countCorrect}/${totalGrades}`}
                        </Grid2>
                    </Grid>
                </IconButton>
            )}
            <div
                style={
                    backImg
                        ? {
                              backgroundImage: 'url(' + backImg + ')',
                              backgroundSize: 'contain',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              height: '50%',
                          }
                        : {
                              height: '50%',
                              backgroundColor: getColor(),
                          }
                }
            />
            <div
                style={{
                    backgroundColor: 'white',
                }}
            >
                <div
                    style={{
                        margin: '10px 10px 10px 10px',
                        fontWeight: 'bolder',
                        textAlign: 'center',
                    }}
                >
                    {module?.code
                        ? module?.code + ': ' + module?.title
                        : module?.title}
                </div>
                <div className="Preview-Card" title={module?.description}>
                    {module?.description}
                </div>
            </div>
        </Card>
    );
};

export { ModulePreviewCard };
