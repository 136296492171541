import * as React from 'react';
import { Card } from '@mui/material';
import { ModulePage, Question, UserAnswers } from '../interfaces/Interfaces';
import { MultipleChoice } from '../modules/assignments/MultipleChoice';
import { RichTextReadOnly } from 'mui-tiptap';
import { useExtensionsReadOnly } from '../utilities/RichTextEditor/useExtensions';

interface Props {
    page: ModulePage;
    currentPage: number;
    classID: number | undefined;
}

const PagePreviewForCarousel = ({ page, currentPage, classID }: Props) => {
    const [questions, setQuestions] = React.useState<Array<Question>>();
    const [answers, setAnswers] = React.useState<Array<UserAnswers | null>>([]);
    const extensions = useExtensionsReadOnly({
        placeholder: 'Add your own content here...',
    });

    React.useEffect(() => {
        if (
            page?.data?.startsWith('{"questions":') ||
            page?.data?.startsWith('<p>{"questions":')
        ) {
            const quests: any = {
                ...JSON.parse(page?.data || ''),
            };
            setQuestions(quests?.questions);
        }
    }, [currentPage]);

    return (
        <Card
            sx={{
                margin: '10px',
                // width: '45%',
                overflow: 'auto',
                zoom: '50%',
            }}
        >
            {page?.data?.length ? (
                <>
                    {(page?.data?.startsWith('{"questions":') ||
                        page?.data?.startsWith('<p>{"questions":')) &&
                    questions?.length ? (
                        <MultipleChoice
                            questions={questions}
                            setAnswers={setAnswers}
                            answers={answers}
                            page={page}
                            setDisableForward={() => {
                                return;
                            }}
                            classID={classID}
                            setViewModuleDetails={() => {
                                return;
                            }}
                            finalPage={false}
                            // gradebookID={}
                        />
                    ) : (
                        <div style={{ margin: '15px' }}>
                            <RichTextReadOnly
                                content={page?.data?.replaceAll(
                                    `class="column">`,
                                    `class="column-readonly">`,
                                )}
                                extensions={extensions}
                            />
                        </div>
                    )}
                </>
            ) : (
                <></>
            )}
        </Card>
    );
};

export { PagePreviewForCarousel };
