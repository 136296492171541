import React from 'react';
import { IconButton } from '@mui/material';
import {
    gridDetailPanelExpandedRowsContentCacheSelector,
    GridRenderCellParams,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid-pro';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const CustomDetailPanelToggle = (
    props: Pick<GridRenderCellParams, 'id' | 'value'>,
) => {
    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    const contentCache = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowsContentCacheSelector,
    );

    const hasDetail = React.isValidElement(contentCache[id]);

    return (
        <IconButton
            size="small"
            tabIndex={-1}
            disabled={!hasDetail}
            aria-label={isExpanded ? 'Close' : 'Open'}
        >
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: (theme) =>
                        theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shortest,
                        }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
};
