import { ThemeProvider } from '@mui/material/styles';
import {
    defaultTheme,
    editUserActive,
    generatePassword,
} from '../utilities/Utilites';
import * as React from 'react';
import Box from '@mui/system/Box';
import { Card, Grid, IconButton } from '@mui/material';
import { CreateAccountModal } from './CreateAccountModal';
import { AuthGet, AuthPost } from '../utilities/Routing/Requests';
import {
    ApiOrganization,
    ApiUser,
    OrganizationUser,
} from '../interfaces/Interfaces';
import { AxiosResponse } from 'axios';
import { useGlobalStore } from '../utilities/GlobalState';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import { EditAccountModal } from './EditAccountModal';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Datagrid } from '../utilities/Datagrid';
import { toast } from 'react-toastify';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';

export const ManagementPage = () => {
    const [createAccountOpen, setCreateAccountOpen] = React.useState(false);
    const [editAccountOpen, setEditAccountOpen] = React.useState(false);
    const [userToEdit, setUserToEdit] = React.useState<OrganizationUser>();
    const [userAccounts, setUserAccounts] = React.useState<Array<ApiUser>>([]);
    // const [customers, setCustomers] = React.useState<Array<ApiCustomer>>([]);
    const [organizations, setOrganizations] = React.useState<
        Array<ApiOrganization>
    >([]);

    const globalState = useGlobalStore((state) => state);
    const getOrganizations = () => {
        AuthGet('/organization/orglist', {
            'x-access-token': globalState?.authToken || '',
            canCache: true,
        })
            .then((res: AxiosResponse<Array<ApiOrganization>>) => {
                setOrganizations(res?.data);
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };
    const getUserList = () =>
        AuthGet('/user/list', {
            'x-access-token': globalState?.authToken || '',
            canCache: true,
        })
            .then((res: AxiosResponse<Array<ApiUser>>) => {
                setUserAccounts(res?.data);
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    // const getCustomers = () =>
    //     AuthGet('/Customers', {
    //         'x-access-token': globalState?.authToken,
    //         canCache: true,
    //     }).then((res: AxiosResponse<Array<ApiCustomer>>) => {
    //         setCustomers(res?.data);
    //     });
    React.useEffect(() => {
        getUserList();
        getOrganizations();
    }, []);
    const handleCreateAccountOpen = () => setCreateAccountOpen(true);
    const handleCreateAccountClose = (refresh?: boolean) => {
        if (refresh) {
            getUserList();
        }
        setCreateAccountOpen(false);
    };
    const handleEditAccountOpen = () => setEditAccountOpen(true);
    const handleEditAccountClose = (refresh?: boolean) => {
        if (refresh) {
            getUserList();
        }
        setEditAccountOpen(false);
    };

    const getRowId = (row: any) => {
        return row.id;
    };

    const toggleUserActive = (email: string, active: boolean) => {
        return editUserActive(email, active, globalState.authToken);
    };

    const sendInvitationEmail = (email: string) => {
        return AuthPost(
            '/organization/userwelcome',
            {
                userEmail: email,
                password: generatePassword(),
            },
            {
                'x-access-token': globalState.authToken,
            },
        )
            .then((userRes) => {
                if (
                    userRes?.status === 200 ||
                    userRes?.status === 201 ||
                    userRes?.status === 204
                ) {
                    toast.success(
                        'User password has been reset and a welcome email was sent!',
                    );
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <CreateAccountModal
                open={createAccountOpen}
                handleClose={handleCreateAccountClose}
            />
            {userToEdit && (
                <EditAccountModal
                    open={editAccountOpen}
                    handleClose={handleEditAccountClose}
                    userAccount={userToEdit}
                    organizations={organizations}
                />
            )}
            <Box
                sx={{
                    paddingTop: '30px',
                    maxWidth: '100vp',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Card sx={{ margin: '10px' }}>
                    <Grid
                        container
                        style={{
                            padding: '10px 10px 10px 20px',
                            backgroundColor: `${defaultTheme.palette.primary.main}`,
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: '20px',
                        }}
                    >
                        <Grid xs={8} sx={{ alignSelf: 'center' }}>
                            <div>User Management</div>
                        </Grid>
                        <Grid
                            xs={4}
                            sx={{
                                textAlignLast: 'right',
                            }}
                        >
                            <IconButton
                                title="Create Account"
                                onClick={handleCreateAccountOpen}
                                sx={{
                                    marginLeft: '5px',
                                    backgroundColor: 'green',
                                    border: 'solid #fff 1px',
                                    color: '#fff',
                                    fontWeight: 'bold',
                                    fontSize: '20px',
                                }}
                            >
                                <PersonAddIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Datagrid
                        getRowId={getRowId}
                        pageSize={20}
                        columns={[
                            {
                                field: 'userEmail',
                                headerName: 'Email',
                                type: 'string',
                                flex: 1,
                            },
                            {
                                field: 'firstName',
                                headerName: 'First Name',
                                type: 'string',
                                flex: 0.5,
                            },
                            {
                                field: 'lastName',
                                headerName: 'Last Name',
                                type: 'string',
                                flex: 0.5,
                            },
                            {
                                field: 'type',
                                headerName: 'Type',
                                type: 'string',
                                flex: 0.5,
                            },
                            {
                                field: 'orgId',
                                headerName: 'Org ID',
                                type: 'string',
                                flex: 0.5,
                            },
                            {
                                field: 'active',
                                headerName: 'Active',
                                type: 'number',
                                flex: 0.25,
                                renderCell: (params) => (
                                    <>
                                        {params?.row?.active ? (
                                            <div style={{ padding: '8px' }}>
                                                <CheckBoxIcon color="success" />
                                            </div>
                                        ) : (
                                            <div style={{ padding: '8px' }}>
                                                <DisabledByDefaultIcon color="error" />
                                            </div>
                                        )}
                                    </>
                                ),
                            },
                            {
                                field: 'Action',
                                headerName: 'Actions',
                                flex: 0.5,
                                sortable: false,
                                renderCell: (params) => (
                                    <>
                                        <IconButton
                                            title="Send Welcome Email (password will be reset)"
                                            onClick={() => {
                                                sendInvitationEmail(
                                                    params?.row?.userEmail,
                                                );
                                            }}
                                        >
                                            <MarkEmailUnreadIcon color="info" />
                                        </IconButton>
                                        <IconButton
                                            title="Edit Details"
                                            onClick={() => {
                                                setUserToEdit(params?.row);
                                                handleEditAccountOpen();
                                            }}
                                        >
                                            <SettingsApplicationsIcon color="warning" />
                                        </IconButton>
                                        <IconButton
                                            title={
                                                !params?.row?.active
                                                    ? 'Click to Re-Activate'
                                                    : 'Click to De-Activate'
                                            }
                                            onClick={() => {
                                                return toggleUserActive(
                                                    params?.row?.userEmail,
                                                    !params?.row?.active,
                                                ).then(() => getUserList());
                                            }}
                                        >
                                            {!params?.row?.active ? (
                                                <PersonIcon color="success" />
                                            ) : (
                                                <PersonOffIcon color="error" />
                                            )}
                                        </IconButton>
                                    </>
                                ),
                            },
                        ]}
                        data={userAccounts}
                    />
                </Card>
            </Box>
        </ThemeProvider>
    );
};
