import * as React from 'react';
import { Card, Grid, IconButton } from '@mui/material';
import { ModulePreviewCard } from '../modules/ModulePreviewCard';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Equipment, Module, ModulePage } from '../interfaces/Interfaces';
import { ModuleDetails } from '../modules/ModuleDetails';
import { defaultTheme, formatDateWithTime2 } from '../utilities/Utilites';
import { Datagrid } from '../utilities/Datagrid';
import { DateTime } from 'luxon';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { useGlobalStore } from '../utilities/GlobalState';

interface Props {
    viewModuleDetails: number;
    handleToggleCreateModule: (refresh?: boolean) => void;
    modules: Array<Module>;
    setViewModuleDetails: (id: number) => void;
    pages: Array<ModulePage>;
    setPages: (pages: Array<ModulePage>) => void;
    getModules: () => Promise<Array<Module>>;
    getModulePages: (moduleId: number) => Promise<Array<ModulePage>>;
    toggleEditPage: (modPage: ModulePage) => void;
    toggleDeletePageModal: (id: number | undefined, refresh?: boolean) => void;
    toggleDeleteModuleModal: (id: number | undefined) => void;
    handleToggleCreateModulePage: (edit?: boolean) => void;
    handleToggleCreateAssignmentPage: (edit?: boolean) => void;
    cardView?: boolean;
    equipmentList?: Array<Equipment>;
}

const ModulesTab = ({
    viewModuleDetails,
    handleToggleCreateModule,
    modules,
    setViewModuleDetails,
    pages,
    setPages,
    getModules,
    getModulePages,
    toggleEditPage,
    toggleDeletePageModal,
    toggleDeleteModuleModal,
    handleToggleCreateModulePage,
    handleToggleCreateAssignmentPage,
    cardView,
    equipmentList,
}: Props) => {
    const globalState = useGlobalStore((state) => state);
    return (
        <>
            {cardView ? (
                <Grid
                    container
                    // spacing={1}
                    // key={'slider-root-grid-container'}
                >
                    {viewModuleDetails === 0 && (
                        <Card
                            onClick={() => handleToggleCreateModule(false)}
                            sx={{
                                width: '300px',
                                height: '300px',
                                margin: '10px',
                                textAlign: 'center',
                                boxShadow:
                                    '0px 2px 11px -1px rgba(0,0,0,4.8),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                            }}
                        >
                            <AddCircleIcon
                                key={'blog-grid-slider-add-icon'}
                                sx={{
                                    color: `${defaultTheme.palette.primary.main}`,
                                    paddingTop: '65px',
                                    width: '150px',
                                    height: '150px',
                                }}
                            />
                        </Card>
                    )}
                    {viewModuleDetails === 0 &&
                        modules.map((module) => {
                            return (
                                <ModulePreviewCard
                                    key={module.id}
                                    handleToggleModulePage={() =>
                                        setViewModuleDetails(module.id)
                                    }
                                    module={module}
                                />
                            );
                        })}
                </Grid>
            ) : (
                <>
                    {viewModuleDetails === 0 &&
                        (globalState?.user?.type?.includes('Admin') ||
                            globalState?.user?.type?.includes(
                                'Instructor',
                            )) && (
                            <Datagrid
                                sx={{ height: 'unset' }}
                                editMode="row"
                                columns={[
                                    {
                                        field: 'code',
                                        headerName: 'Code',
                                        type: 'string',
                                        flex: 0.25,
                                    },
                                    {
                                        field: 'title',
                                        headerName: 'Title',
                                        type: 'string',
                                        flex: 1,
                                    },
                                    {
                                        field: 'description',
                                        headerName: 'Description',
                                        type: 'string',
                                        flex: 1,
                                    },
                                    {
                                        field: 'endDate',
                                        headerName: 'Due Date',
                                        type: 'string',
                                        flex: 1,
                                        renderCell: (rowData) => {
                                            const date = DateTime.fromISO(
                                                rowData.row.endDate as any,
                                            ) as any;
                                            return rowData?.row?.endDate
                                                ? formatDateWithTime2(date)
                                                : 'Unknown';
                                        },
                                    },
                                    {
                                        field: 'action',
                                        headerName: 'Action',
                                        flex: 0.5,
                                        sortable: false,
                                        renderCell: (rowData: any) => {
                                            return (
                                                <>
                                                    <IconButton
                                                        sx={{
                                                            marginLeft: '5px',
                                                            backgroundColor:
                                                                '#f1c40f',
                                                            border: 'solid #fff 1px',
                                                            color: '#fff',
                                                            fontWeight: 'bold',
                                                            fontSize: '20px',
                                                        }}
                                                        title="Edit"
                                                        onClick={() =>
                                                            setViewModuleDetails(
                                                                rowData?.row
                                                                    ?.id,
                                                            )
                                                        }
                                                    >
                                                        <SettingsApplicationsIcon />
                                                    </IconButton>
                                                </>
                                            );
                                        },
                                    },
                                ]}
                                data={modules}
                            />
                        )}
                </>
            )}
            {viewModuleDetails !== 0 && modules?.length && (
                <ModuleDetails
                    handleToggleModulePage={setViewModuleDetails}
                    handleToggleCreateModulePage={handleToggleCreateModulePage}
                    handleToggleCreateAssignmentPage={
                        handleToggleCreateAssignmentPage
                    }
                    module={
                        modules?.filter(
                            (module) => module.id === viewModuleDetails,
                        )[0]
                    }
                    pages={pages}
                    setPages={setPages}
                    getModules={getModules}
                    getModulePages={getModulePages}
                    toggleEditPage={toggleEditPage}
                    toggleDeletePageModal={toggleDeletePageModal}
                    toggleDeleteModuleModal={toggleDeleteModuleModal}
                    equipmentList={equipmentList || []}
                />
            )}
        </>
    );
};

export { ModulesTab };
