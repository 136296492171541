import { ThemeProvider } from '@mui/material/styles';
import { defaultTheme } from '../utilities/Utilites';
import * as React from 'react';
import Box from '@mui/system/Box';
import { Equipment, GlobalState } from '../interfaces/Interfaces';
import { Card, Grid, IconButton } from '@mui/material';
import { Datagrid } from '../utilities/Datagrid';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import { AuthGet } from '../utilities/Routing/Requests';
import { AxiosResponse } from 'axios';
import { useGlobalStore } from '../utilities/GlobalState';
import { toast } from 'react-toastify';
import { CreateEquipmentModal } from './CreateEquipmentModal';

export const EquipmentView = () => {
    const [equipment, setEquipment] = React.useState<Array<Equipment>>([]);
    const [createModalIsOpen, setCreateModalIsOpen] = React.useState(false);

    const globalState = useGlobalStore((state: GlobalState) => state);

    const getEquipment = () => {
        AuthGet('/equipment/equipmentlist', {
            'x-access-token': globalState?.authToken || '',
            canCache: true,
        })
            .then((res: AxiosResponse<Array<Equipment>>) => {
                setEquipment(res?.data);
                return res?.data;
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    React.useEffect(() => {
        getEquipment();
    }, []);

    return (
        <ThemeProvider theme={defaultTheme}>
            {CreateEquipmentModal({
                open: createModalIsOpen,
                handleClose: () => setCreateModalIsOpen(false),
                refreshEquipmentList: getEquipment,
            })}
            <Box
                sx={{
                    paddingTop: '30px',
                }}
            >
                <Card sx={{ margin: '10px' }}>
                    <Grid
                        container
                        style={{
                            padding: '10px 10px 10px 20px',
                            backgroundColor: `${defaultTheme.palette.primary.main}`,
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: '20px',
                        }}
                    >
                        <Grid item xs={8} sx={{ alignSelf: 'center' }}>
                            <div>Equipment Management</div>
                        </Grid>
                        {globalState?.user?.type?.includes('APTAdmin') ? (
                            <Grid
                                item
                                xs={4}
                                sx={{
                                    textAlignLast: 'right',
                                }}
                            >
                                <IconButton
                                    key={'blog-grid-slider-add-iconbutton'}
                                    title="Create Equipment"
                                    onClick={() =>
                                        setCreateModalIsOpen(!createModalIsOpen)
                                    }
                                >
                                    <PrecisionManufacturingIcon
                                        sx={{
                                            color: '#fff',
                                        }}
                                    />
                                </IconButton>
                            </Grid>
                        ) : (
                            <Grid
                                item
                                xs={4}
                                sx={{
                                    textAlignLast: 'right',
                                }}
                            />
                        )}
                    </Grid>
                    <Datagrid
                        columns={[
                            {
                                field: 'id',
                                headerName: 'ID',
                                type: 'string',
                                flex: 0.25,
                            },
                            {
                                field: 'title',
                                headerName: 'Title',
                                type: 'string',
                                flex: 1,
                            },
                            {
                                field: 'description',
                                headerName: 'Description',
                                type: 'string',
                                flex: 1,
                                // editable: true,
                            },
                            {
                                field: 'category',
                                headerName: 'Category',
                                type: 'string',
                                flex: 1,
                                // editable: true,
                            },
                            {
                                field: 'active',
                                headerName: 'Active',
                                type: 'boolean',
                                flex: 0.25,
                                // editable: true,
                            },
                            // {
                            //     field: 'action',
                            //     headerName: 'Action',
                            //     flex: 1,
                            //     sortable: false,
                            //     renderCell: (params) => {
                            //         return (
                            //             <>
                            //                 <IconButton
                            //                     sx={{
                            //                         backgroundColor: 'green',
                            //                         border: 'solid #fff 1px',
                            //                         color: '#fff',
                            //                         fontWeight: 'bold',
                            //                         fontSize: '20px',
                            //                     }}
                            //                     onClick={() =>
                            //                         toggleDetailsModal(
                            //                             params?.row,
                            //                         )
                            //                     }
                            //                 >
                            //                     <ListAltIcon />
                            //                 </IconButton>
                            //                 <IconButton
                            //                     sx={{
                            //                         marginLeft: '5px',
                            //                         backgroundColor: '#f1c40f',
                            //                         border: 'solid #fff 1px',
                            //                         color: '#fff',
                            //                         fontWeight: 'bold',
                            //                         fontSize: '20px',
                            //                     }}
                            //                     onClick={() =>
                            //                         toggleEditModal(params?.row)
                            //                     }
                            //                 >
                            //                     <SettingsApplicationsIcon />
                            //                 </IconButton>
                            //             </>
                            //         );
                            //     },
                            // },
                        ]}
                        data={equipment}
                    />
                </Card>
            </Box>
        </ThemeProvider>
    );
};
