import * as React from 'react';
import Box from '@mui/system/Box';
import {
    IconButton,
    Card,
    Grid,
    Typography,
    Modal,
    ThemeProvider,
} from '@mui/material';
import {
    ApiOrganization,
    Distributor,
    OrganizationUser,
} from '../interfaces/Interfaces';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import DeleteIcon from '@mui/icons-material/Delete';
import { Datagrid } from '../utilities/Datagrid';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { defaultTheme, toggleActiveUser } from '../utilities/Utilites';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

export interface Props {
    organization: ApiOrganization;
    toggleEditModal: (org?: ApiOrganization) => void;
    toggleAddInstuctorModal: () => void;
    handleAddStudentModal: (student: OrganizationUser | null) => void;
    toggleAddContactModal: () => void;
    open: boolean;
    handleIsOpen: (organization?: ApiOrganization | null) => void;
    toggleEditInstuctorModal: (instructor?: OrganizationUser | null) => void;
    authToken: string;
    activeUsers: Array<any>;
    toggleBulkAddStudentModal: () => void;
    distributorList: Array<Distributor>;
}

export const OrganizationDetailsModal = ({
    organization,
    toggleEditModal,
    toggleAddInstuctorModal,
    handleAddStudentModal,
    toggleAddContactModal,
    open,
    handleIsOpen,
    toggleEditInstuctorModal,
    authToken,
    activeUsers,
    toggleBulkAddStudentModal,
    distributorList,
}: Props) => {
    return (
        <ThemeProvider theme={defaultTheme}>
            <Modal
                open={open}
                onClose={() => handleIsOpen(null)}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    overflow: 'auto',
                }}
            >
                <Box
                    key={'orgCard'}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Card
                        sx={{
                            margin: '10px',
                            width: '100%',
                            padding: '0 0 10px 0',
                        }}
                    >
                        <Grid
                            container
                            style={{
                                padding: '10px 10px 10px 20px',
                                backgroundColor: `${defaultTheme.palette.primary.main}`,
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: '20px',
                            }}
                        >
                            <Grid xs={8} sx={{ alignSelf: 'center' }}>
                                <div>{organization.name} Details</div>
                            </Grid>
                            <Grid
                                xs={4}
                                sx={{
                                    textAlignLast: 'right',
                                }}
                            >
                                <IconButton
                                    sx={{
                                        backgroundColor: '#f1c40f',
                                        border: 'solid #fff 1px',
                                        color: '#fff',
                                        fontWeight: 'bold',
                                        fontSize: '20px',
                                    }}
                                    onClick={() =>
                                        toggleEditModal(organization)
                                    }
                                >
                                    <SettingsApplicationsIcon />
                                </IconButton>
                                <IconButton
                                    sx={{
                                        marginLeft: '5px',
                                        backgroundColor: '#e74c3c',
                                        border: 'solid #fff 1px',
                                        color: '#fff',
                                        fontWeight: 'bold',
                                        fontSize: '20px',
                                    }}
                                    onClick={() =>
                                        toggleEditModal(organization)
                                    }
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid sx={{ padding: '10px 10px 0 10px' }} container>
                            <Grid item xs={12} md={3} sx={{ padding: '10px' }}>
                                <Typography
                                    display="block"
                                    variant="body1"
                                    style={{
                                        fontSize: '14px',
                                        color: '#868e96',
                                        display: 'inline-block',
                                    }}
                                >
                                    Organization Phone #:
                                </Typography>
                                <Typography
                                    style={{
                                        display: 'inline-block',
                                        color: 'rgb(117 121 125)',
                                        paddingLeft: '10px',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    }}
                                >
                                    {organization.phone}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ padding: '10px' }}>
                                <Typography
                                    display="block"
                                    variant="body1"
                                    style={{
                                        fontSize: '14px',
                                        color: '#868e96',
                                        display: 'inline-block',
                                    }}
                                >
                                    Type:
                                </Typography>
                                <Typography
                                    style={{
                                        display: 'inline-block',
                                        color: 'rgb(117 121 125)',
                                        paddingLeft: '10px',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    }}
                                >
                                    {organization.type}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ padding: '10px' }}>
                                <Typography
                                    display="block"
                                    variant="body1"
                                    style={{
                                        fontSize: '14px',
                                        color: '#868e96',
                                        display: 'inline-block',
                                    }}
                                >
                                    Parent Organization:
                                </Typography>
                                <Typography
                                    style={{
                                        display: 'inline-block',
                                        color: 'rgb(117 121 125)',
                                        paddingLeft: '10px',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    }}
                                >
                                    {organization.parent}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ padding: '10px' }}>
                                <Typography
                                    display="block"
                                    variant="body1"
                                    style={{
                                        fontSize: '14px',
                                        color: '#868e96',
                                        display: 'inline-block',
                                    }}
                                >
                                    Billing Type:
                                </Typography>
                                <Typography
                                    style={{
                                        display: 'inline-block',
                                        color: 'rgb(117 121 125)',
                                        paddingLeft: '10px',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    }}
                                >
                                    {organization.billType}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid sx={{ padding: '0 10px 0 10px' }} container>
                            <Grid item xs={12} md={3} sx={{ padding: '10px' }}>
                                <Typography
                                    display="block"
                                    variant="body1"
                                    style={{
                                        fontSize: '14px',
                                        color: '#868e96',
                                        display: 'inline-block',
                                    }}
                                >
                                    Reseller:
                                </Typography>
                                <Typography
                                    style={{
                                        display: 'inline-block',
                                        color: 'rgb(117 121 125)',
                                        paddingLeft: '10px',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    }}
                                >
                                    {distributorList?.filter((dist) => {
                                        return (
                                            dist?.id?.toString() ===
                                            organization?.reseller
                                        );
                                    })[0]?.name || ''}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ padding: '10px' }}>
                                <Typography
                                    display="block"
                                    variant="body1"
                                    style={{
                                        fontSize: '14px',
                                        color: '#868e96',
                                        display: 'inline-block',
                                    }}
                                >
                                    Address Line 1:
                                </Typography>
                                <Typography
                                    style={{
                                        display: 'inline-block',
                                        color: 'rgb(117 121 125)',
                                        paddingLeft: '10px',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    }}
                                >
                                    {organization.addressLine1}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ padding: '10px' }}>
                                <Typography
                                    display="block"
                                    variant="body1"
                                    style={{
                                        fontSize: '14px',
                                        color: '#868e96',
                                        display: 'inline-block',
                                    }}
                                >
                                    Address Line 2:
                                </Typography>
                                <Typography
                                    style={{
                                        display: 'inline-block',
                                        color: 'rgb(117 121 125)',
                                        paddingLeft: '10px',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    }}
                                >
                                    {organization.addressLine2}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ padding: '10px' }}>
                                <Typography
                                    display="block"
                                    variant="body1"
                                    style={{
                                        fontSize: '14px',
                                        color: '#868e96',
                                        display: 'inline-block',
                                    }}
                                >
                                    City:
                                </Typography>
                                <Typography
                                    style={{
                                        display: 'inline-block',
                                        color: 'rgb(117 121 125)',
                                        paddingLeft: '10px',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    }}
                                >
                                    {organization.city}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid sx={{ padding: '0 10px 0 10px' }} container>
                            <Grid item xs={12} md={3} sx={{ padding: '10px' }}>
                                <Typography
                                    display="block"
                                    variant="body1"
                                    style={{
                                        fontSize: '14px',
                                        color: '#868e96',
                                        display: 'inline-block',
                                    }}
                                >
                                    State:
                                </Typography>
                                <Typography
                                    style={{
                                        display: 'inline-block',
                                        color: 'rgb(117 121 125)',
                                        paddingLeft: '10px',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    }}
                                >
                                    {organization.state}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ padding: '10px' }}>
                                <Typography
                                    display="block"
                                    variant="body1"
                                    style={{
                                        fontSize: '14px',
                                        color: '#868e96',
                                        display: 'inline-block',
                                    }}
                                >
                                    Zip Code:
                                </Typography>
                                <Typography
                                    style={{
                                        display: 'inline-block',
                                        color: 'rgb(117 121 125)',
                                        paddingLeft: '10px',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    }}
                                >
                                    {organization.zipcode}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ padding: '10px' }}>
                                <Typography
                                    display="block"
                                    variant="body1"
                                    style={{
                                        fontSize: '14px',
                                        color: '#868e96',
                                        display: 'inline-block',
                                    }}
                                >
                                    Status:
                                </Typography>
                                <Typography
                                    style={{
                                        display: 'inline-block',
                                        color: 'rgb(117 121 125)',
                                        paddingLeft: '10px',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    }}
                                >
                                    {organization.active}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid xs={8} sx={{ alignSelf: 'center' }}>
                                <div
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '20px',
                                        padding: '0 10px 0px 20px',
                                        // backgroundColor: `${defaultTheme.palette.primary.main}`,
                                        color: `${defaultTheme.palette.primary.main}`,
                                    }}
                                >
                                    Contacts
                                </div>
                            </Grid>
                            <Grid
                                xs={4}
                                sx={{
                                    textAlignLast: 'right',
                                    paddingRight: '20px',
                                }}
                            >
                                <IconButton
                                    title={'Create Contact'}
                                    sx={{
                                        color: 'green',
                                        border: 'solid #fff 1px',
                                        padding: 0,
                                        // fontWeight: 'bold',
                                        // fontSize: '20px',
                                    }}
                                    onClick={toggleAddContactModal}
                                >
                                    <PersonAddAlt1Icon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <hr
                            style={{
                                border: `1px solid ${defaultTheme.palette.primary.main}`,
                            }}
                        />
                        {organization?.contacts?.map((contact, i) => {
                            return (
                                <Grid
                                    sx={{ padding: '0 10px 0 10px' }}
                                    key={'orgContactsCard' + i}
                                    container
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        md={2}
                                        sx={{ padding: '10px' }}
                                    >
                                        <Typography
                                            display="block"
                                            variant="body1"
                                            style={{
                                                fontSize: '14px',
                                                color: '#868e96',
                                                display: 'inline-block',
                                            }}
                                        >
                                            Type:
                                        </Typography>
                                        <Typography
                                            style={{
                                                display: 'inline-block',
                                                color: 'rgb(117 121 125)',
                                                paddingLeft: '10px',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                            }}
                                        >
                                            {contact?.type}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={2}
                                        sx={{ padding: '10px' }}
                                    >
                                        <Typography
                                            display="block"
                                            variant="body1"
                                            style={{
                                                fontSize: '14px',
                                                color: '#868e96',
                                                display: 'inline-block',
                                            }}
                                        >
                                            First Name:
                                        </Typography>
                                        <Typography
                                            style={{
                                                display: 'inline-block',
                                                color: 'rgb(117 121 125)',
                                                paddingLeft: '10px',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                            }}
                                        >
                                            {contact?.firstName}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={2}
                                        sx={{ padding: '10px' }}
                                    >
                                        <Typography
                                            display="block"
                                            variant="body1"
                                            style={{
                                                fontSize: '14px',
                                                color: '#868e96',
                                                display: 'inline-block',
                                            }}
                                        >
                                            Last Name:
                                        </Typography>
                                        <Typography
                                            style={{
                                                display: 'inline-block',
                                                color: 'rgb(117 121 125)',
                                                paddingLeft: '10px',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                            }}
                                        >
                                            {contact?.lastName}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={3}
                                        sx={{ padding: '10px' }}
                                    >
                                        <Typography
                                            display="block"
                                            variant="body1"
                                            style={{
                                                fontSize: '14px',
                                                color: '#868e96',
                                                display: 'inline-block',
                                            }}
                                        >
                                            Phone #:
                                        </Typography>
                                        <Typography
                                            style={{
                                                display: 'inline-block',
                                                color: 'rgb(117 121 125)',
                                                paddingLeft: '10px',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                            }}
                                        >
                                            {contact?.phoneNumber}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={3}
                                        sx={{ padding: '10px' }}
                                    >
                                        <Typography
                                            display="block"
                                            variant="body1"
                                            style={{
                                                fontSize: '14px',
                                                color: '#868e96',
                                                display: 'inline-block',
                                            }}
                                        >
                                            Email:
                                        </Typography>
                                        <Typography
                                            style={{
                                                display: 'inline-block',
                                                color: 'rgb(117 121 125)',
                                                paddingLeft: '10px',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                            }}
                                        >
                                            {contact?.email}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ padding: '0', margin: '0' }}
                                    >
                                        {organization?.contacts?.length >
                                        i + 1 ? (
                                            <hr
                                                style={{
                                                    border: '1px dashed #868e96',
                                                }}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </Grid>
                                </Grid>
                            );
                        })}
                        <Grid item xs={12} md={3}>
                            <Grid container>
                                <Grid xs={8} sx={{ alignSelf: 'center' }}>
                                    <div
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                            padding: '0 10px 0px 20px',
                                            // backgroundColor: `${defaultTheme.palette.primary.main}`,
                                            color: `${defaultTheme.palette.primary.main}`,
                                        }}
                                    >
                                        Users
                                    </div>
                                </Grid>
                                <Grid
                                    xs={4}
                                    sx={{
                                        textAlignLast: 'right',
                                        paddingRight: '20px',
                                    }}
                                >
                                    <IconButton
                                        title={'Add Users From CSV'}
                                        sx={{
                                            color: 'green',
                                            border: 'solid #fff 1px',
                                            padding: 0,
                                            // fontWeight: 'bold',
                                            // fontSize: '20px',
                                        }}
                                        onClick={toggleBulkAddStudentModal}
                                    >
                                        <GroupAddIcon />
                                    </IconButton>
                                    <IconButton
                                        title={'Create Instructor'}
                                        sx={{
                                            color: 'green',
                                            border: 'solid #fff 1px',
                                            padding: 0,
                                            // fontWeight: 'bold',
                                            // fontSize: '20px',
                                        }}
                                        onClick={toggleAddInstuctorModal}
                                    >
                                        <PersonAddAlt1Icon />
                                    </IconButton>
                                    <IconButton
                                        title={'Create Student'}
                                        sx={{
                                            color: 'green',
                                            border: 'solid #fff 1px',
                                            padding: 0,
                                            // fontWeight: 'bold',
                                            // fontSize: '20px',
                                        }}
                                        onClick={() => {
                                            handleAddStudentModal(null);
                                        }}
                                    >
                                        <PersonAddAlt1Icon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <hr
                                style={{
                                    border: `1px solid ${defaultTheme.palette.primary.main}`,
                                }}
                            />
                            <Datagrid
                                sx={{ padding: '10px' }}
                                columns={[
                                    {
                                        field: 'id',
                                        headerName: 'ID',
                                        type: 'string',
                                        flex: 0.25,
                                    },
                                    {
                                        field: 'firstName',
                                        headerName: 'First Name',
                                        type: 'string',
                                        flex: 1,
                                    },
                                    {
                                        field: 'lastName',
                                        headerName: 'Last Name',
                                        type: 'string',
                                        flex: 1,
                                        // editable: true,
                                    },
                                    {
                                        field: 'userEmail',
                                        headerName: 'Email',
                                        type: 'string',
                                        flex: 1,
                                        // editable: true,
                                    },
                                    {
                                        field: 'type',
                                        headerName: 'Type',
                                        type: 'string',
                                        flex: 1,
                                        // editable: true,
                                    },
                                    {
                                        field: 'active',
                                        headerName: 'Active',
                                        type: 'boolean',
                                        flex: 0.25,
                                        // editable: true,
                                    },
                                    {
                                        field: 'action',
                                        headerName: 'Action',
                                        flex: 0.75,
                                        sortable: false,
                                        renderCell: (params) => {
                                            return (
                                                <>
                                                    <IconButton
                                                        sx={{
                                                            marginLeft: '5px',
                                                            backgroundColor:
                                                                '#f1c40f',
                                                            border: 'solid #fff 1px',
                                                            color: '#fff',
                                                            fontWeight: 'bold',
                                                            fontSize: '20px',
                                                        }}
                                                        title={'Edit User'}
                                                        onClick={() =>
                                                            params?.row?.type?.includes(
                                                                'Student',
                                                            )
                                                                ? handleAddStudentModal(
                                                                      params?.row,
                                                                  )
                                                                : toggleEditInstuctorModal(
                                                                      params?.row,
                                                                  )
                                                        }
                                                    >
                                                        <SettingsApplicationsIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        sx={{
                                                            marginLeft: '5px',
                                                            backgroundColor:
                                                                '#e74c3c',
                                                            border: 'solid #fff 1px',
                                                            color: '#fff',
                                                            fontWeight: 'bold',
                                                            fontSize: '20px',
                                                        }}
                                                        title={
                                                            'Delete Instructor'
                                                        }
                                                        onClick={() => {
                                                            toggleActiveUser(
                                                                false,
                                                                params?.row
                                                                    ?.userEmail,
                                                                authToken,
                                                            );
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </>
                                            );
                                        },
                                    },
                                ]}
                                data={activeUsers}
                            />
                        </Grid>
                    </Card>
                </Box>
            </Modal>
        </ThemeProvider>
    );
};
