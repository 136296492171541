import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Card, CssBaseline, TextField } from '@mui/material';
import { OrganizationUser } from '../interfaces/Interfaces';
import { AuthPost } from '../utilities/Routing/Requests';
import { toast } from 'react-toastify';
export interface Props {
    open: boolean;
    handleClose: (instructor?: OrganizationUser | null) => void;
    instructor: OrganizationUser;
    authToken: string;
    orgId: string;
}

export const EditInstructorModal = ({
    open,
    handleClose,
    instructor,
    authToken,
    orgId,
}: Props) => {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        const userBody = {
            userEmail: instructor?.userEmail,
            firstName: data.get('firstName'),
            lastName: data.get('lastName'),
            orgId: orgId,
            type: instructor?.type,
            id: instructor?.id,
            // customerId: customer?.replace(/(^.*\(|\).*$)/g, '') || '',
        };

        if (
            instructor.firstName !== userBody.firstName ||
            instructor.lastName !== userBody.lastName ||
            instructor.type !== userBody.type ||
            instructor.userEmail !== userBody.userEmail ||
            instructor.orgId !== userBody.orgId
        ) {
            AuthPost('/user/editfromid', userBody, {
                'x-access-token': authToken,
            })
                .then((userRes) => {
                    if (userRes?.status === 201 || userRes?.status === 204) {
                        toast.success('Account Details Edited Successfully!');
                        handleClose();
                    }
                })
                .catch((err) => {
                    toast.error(err.toString());
                });
        } else {
            handleClose();
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => handleClose(null)}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Card sx={{ width: '800px', margin: '10px' }}>
                <CssBaseline />
                <Box
                    sx={{
                        margin: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Edit User
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="firstName"
                            label="First Name"
                            name="firstName"
                            autoFocus
                            defaultValue={instructor?.firstName}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                            autoFocus
                            defaultValue={instructor?.lastName}
                        />
                        {/* <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoFocus
                            defaultValue={instructor?.userEmail}
                        /> */}
                        {/* <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="phoneNumber"
                            label="Phone #"
                            name="phoneNumber"
                            autoFocus
                            defaultValue={instructor?.phoneNumber}
                        /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            SAVED CHANGES
                        </Button>
                    </Box>
                </Box>
            </Card>
        </Modal>
    );
};
