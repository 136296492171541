import React, { useState, useEffect, useRef } from 'react';
import JSZip from 'jszip';
import Loading from '../utilities/loading/Loading';
import { AuthPost } from '../utilities/Routing/Requests';
import { useGlobalStore } from '../utilities/GlobalState';
import { toast } from 'react-toastify';

interface Props {
    scormUrl: string;
    moduleId: number;
    resetModule: () => void;
}

interface CourseItem {
    id: string;
    title: string;
    launchUrl?: string;
}

// Extend the Window interface to include the properties
declare global {
    interface Window {
        API?: any;
        RXDConfig?: any;
        files?: any;
        rxdHostUrl?: string;
    }
}

const ScormWrapper: React.FC<Props> = ({ scormUrl, moduleId, resetModule }) => {
    const [htmlContent, setHtmlContent] = useState('');
    const [loading, setLoading] = useState(true);
    const [unzippedFiles, setUnzippedFiles] = useState<any>({});
    const [scormData, setScormData] = useState<any>({});
    const [contentInjected, setContentInjected] = useState(false);

    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const globalState = useGlobalStore((state) => state);

    let apiInitialized = false;
    let apiLastError = '';

    const forceSixDigits = (numString: string) => {
        while (numString.length < 6) {
            numString = '0' + numString;
        }
        return numString;
    };

    React.useEffect(() => {
        const body = {
            moduleId: moduleId,
            userId: forceSixDigits(globalState?.user?.id?.toString() || '0'),
        };
        AuthPost('/scormdata/getscormdata', body, {
            'x-access-token': globalState.authToken,
        })
            .then((res) => {
                if (res?.status === 200 && res?.data?.lessonData) {
                    setScormData(JSON.parse(res.data.lessonData));
                    return scormData;
                }
            })
            .then(() => {
                setLoading(false);
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    }, []);

    const unzipFiles = async (zipBlob: Blob) => {
        const zip = await JSZip.loadAsync(zipBlob);
        const files: { [key: string]: string } = {};
        await Promise.all(
            Object.keys(zip.files).map(async (relativePath) => {
                const unzippedFile = zip.file(relativePath);
                if (unzippedFile) {
                    files[relativePath] = await unzippedFile.async('string');
                }
            }),
        );
        return files;
    };

    const displayLog = (message: string): void => {
        console.log(message);
    };

    const commitData = (modId: number, data: any) => {
        const body = {
            lessonData: JSON.stringify(data),
            moduleId: modId,
            userId: forceSixDigits(globalState?.user?.id?.toString() || '0'),
        };
        AuthPost('/scormdata/savescormdata', body, {
            'x-access-token': globalState.authToken,
        })
            .then((res) => {
                if (res?.status === 200) {
                    console.log('Scorm data saved successfully');
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    const timeToSeconds = (sessionTime: string): number => {
        if (sessionTime === '' || !sessionTime) {
            return 0;
        } else {
            const pos1 = sessionTime.indexOf(':') + 1;
            const pos2 = sessionTime.indexOf(':', pos1 + 1) + 1;
            let pos3 = sessionTime.indexOf('.', pos2 + 1) + 1;

            if (pos3 === 0) {
                pos3 = sessionTime.length + 1;
            }

            const Hours = sessionTime.substring(0, pos1 - 1);
            const Minutes = sessionTime.substring(pos1, pos2 - 1);
            const Seconds = sessionTime.substring(pos2, pos3 - 1);
            const intHours = parseInt(Hours);
            const intMinutes = parseInt(Minutes);
            const intSeconds = parseInt(Seconds);

            const totalSeconds =
                intHours * 60 * 60 + intMinutes * 60 + intSeconds;

            return totalSeconds;
        }
    };

    const secondsToTime = (totalSeconds: number): string => {
        const intHours = Math.floor(totalSeconds / (60 * 60));
        const intMinutes = Math.floor((totalSeconds - intHours * 60 * 60) / 60);
        const intSeconds = Math.floor(
            totalSeconds - intHours * 60 * 60 - intMinutes * 60,
        );

        const hours = intHours.toString();
        const minutes = ('00' + intMinutes.toString()).substring(
            intMinutes.toString().length,
        );
        const seconds = ('00' + intSeconds.toString()).substring(
            intSeconds.toString().length,
        );

        return hours + ':' + minutes + ':' + seconds;
    };

    useEffect(() => {
        const iframe = iframeRef.current?.contentWindow;
        if (iframe) {
            iframe.postMessage(scormData, '*');
        }
    }, [scormData]);

    const ApiMethods = {
        scormData: scormData,
        updateData: (varName: string, varValue: string | number) => {
            ApiMethods.scormData = {
                ...ApiMethods.scormData,
                [varName]: varValue,
            };
        },
        LMSInitialize: (initializeInput: string): boolean => {
            displayLog('LMSInitialize: ' + initializeInput);
            if (initializeInput !== '') {
                apiLastError = '201';
                return false;
            }
            apiLastError = '0';
            apiInitialized = true;

            ApiMethods.updateData(
                'cmi.core.student_id',
                ApiMethods.scormData?.['cmi.core.student_id'] ||
                    globalState?.user?.id
                    ? forceSixDigits(globalState?.user?.id?.toString() || '0')
                    : '000000',
            );
            ApiMethods.updateData(
                'cmi.core.student_name',
                ApiMethods.scormData?.['cmi.core.student_name'] ||
                    globalState?.user
                    ? `${globalState?.user?.firstName}` +
                          ' ' +
                          `${globalState?.user?.lastName}`
                    : 'error, error',
            );
            ApiMethods.updateData(
                'cmi.core.lesson_mode',
                ApiMethods.scormData?.['cmi.core.lesson_mode'] || 'normal',
            ); // “browse”, “normal”, “review”
            ApiMethods.updateData(
                'cmi.core.lesson_status',
                ApiMethods.scormData?.['cmi.core.lesson_status'] ||
                    'not attempted',
            ); // “passed”, “completed”, “failed”, “incomplete”, “browsed”, “not attempted”, RW
            ApiMethods.updateData(
                'cmi.core.total_time',
                ApiMethods.scormData?.['cmi.core.total_time'] ||
                    '0000:00:00.00',
            );
            ApiMethods.updateData(
                'cmi.core.entry',
                ApiMethods.scormData?.['cmi.core.entry'],
            );
            ApiMethods.updateData(
                'cmi.core._children',
                ApiMethods.scormData?.['cmi.core._children'] ||
                    'student_id,student_name,lesson_location,credit,lesson_status,entry,score,total_time,lesson_mode,exit,session_time,suspend_data,launch_data',
            );
            ApiMethods.updateData(
                'cmi.core.score._children',
                ApiMethods.scormData?.['cmi.core.score._children'] ||
                    'raw,min,max',
            );
            ApiMethods.updateData('cmi.core.session_time', '0000:00:00.00');
            ApiMethods.updateData(
                'cmi.student_data._children',
                ApiMethods.scormData?.['cmi.student_data._children'] ||
                    'mastery_score,time_limit_action,max_time_allowed',
            );
            return true;
        },

        LMSGetValue: (varname: string): string => {
            displayLog('LMSGetValue: ' + varname);
            if (apiInitialized) {
                switch (varname) {
                    case 'cmi.core.student_id':
                        return globalState?.user?.id
                            ? forceSixDigits(globalState?.user?.id?.toString())
                            : '000000';
                    case 'cmi.core.student_name':
                        return globalState?.user
                            ? `${globalState?.user?.firstName}` +
                                  ' ' +
                                  `${globalState?.user?.lastName}`
                            : 'anon., anon.';
                    case 'cmi.core._children':
                    case 'cmi.core.lesson_location':
                    case 'cmi.core.credit':
                    case 'cmi.core.lesson_status':
                    case 'cmi.core.entry':
                    case 'cmi.core.score._children':
                    case 'cmi.core.score.raw':
                    case 'cmi.core.score.min':
                    case 'cmi.core.score.max':
                    case 'cmi.suspend_data':
                    case 'cmi.launch_data':
                    case 'cmi.core.lesson_mode':
                    case 'cmi.student_data._children':
                    case 'cmi.student_data.mastery_score':
                    case 'cmi.student_data.max_time_allowed':
                    case 'cmi.student_data.time_limit_action':
                    case 'cmi.core.total_time':
                    case 'cmi.progress_measure':
                        apiLastError = '0';
                        return ApiMethods?.scormData?.[varname]
                            ? scormData[varname]
                            : '';
                    case 'cmi.core.exit':
                    case 'cmi.core.session_time':
                        apiLastError = '404';
                        return ApiMethods?.scormData?.[varname]
                            ? scormData[varname]
                            : '';
                    default:
                        apiLastError = '401';
                        return ApiMethods?.scormData?.[varname]
                            ? scormData[varname]
                            : '';
                }
            } else {
                apiLastError = '301';
                return '';
            }
        },

        LMSSetValue: (varname: string, varvalue: string): string => {
            displayLog('LMSSetValue: ' + varname + '=' + varvalue);
            if (apiInitialized) {
                let upperCaseLessonStatus = '';
                let upperCaseExit = '';
                const re = /^[0-9]{2,4}:[0-9]{2}:[0-9]{2}(.[0-9]{1,2})?$/;
                let timeArray: string[] = [];

                switch (varname) {
                    case 'cmi.core._children':
                    case 'cmi.core.score._children':
                    case 'cmi.student_data._children':
                        apiLastError = '402';
                        ApiMethods.updateData(varname, varvalue);
                        return 'false';
                    case 'cmi.core.student_id':
                    case 'cmi.core.student_name':
                    case 'cmi.core.credit':
                    case 'cmi.core.entry':
                    case 'cmi.launch_data':
                    case 'cmi.core.lesson_mode':
                    case 'cmi.student_data.mastery_score':
                    case 'cmi.student_data.max_time_allowed':
                    case 'cmi.student_data.time_limit_action':
                        apiLastError = '403';
                        ApiMethods.updateData(varname, varvalue);
                        return 'false';
                    case 'cmi.core.total_time':
                        const totalTime = secondsToTime(
                            timeToSeconds(
                                ApiMethods.scormData?.['cmi.core.total_time'],
                            ) +
                                timeToSeconds(
                                    ApiMethods.scormData?.[
                                        'cmi.core.session_time'
                                    ],
                                ),
                        );
                        ApiMethods.updateData(varname, totalTime);
                        return 'true';
                    case 'cmi.core.lesson_location':
                        ApiMethods.updateData(varname, varvalue);
                        apiLastError = '0';
                        return 'true';
                    case 'cmi.core.lesson_status':
                        upperCaseLessonStatus = varvalue.toUpperCase();
                        if (
                            upperCaseLessonStatus !== 'PASSED' &&
                            upperCaseLessonStatus !== 'FAILED' &&
                            upperCaseLessonStatus !== 'COMPLETED' &&
                            upperCaseLessonStatus !== 'INCOMPLETE' &&
                            upperCaseLessonStatus !== 'BROWSED' &&
                            upperCaseLessonStatus !== 'NOT ATTEMPTED'
                        ) {
                            apiLastError = '405';
                            return 'false';
                        }
                        ApiMethods.updateData(varname, varvalue);
                        apiLastError = '0';
                        return 'true';
                    case 'cmi.core.score.raw':
                    case 'cmi.core.score.min':
                    case 'cmi.core.score.max':
                        if (
                            isNaN(parseInt(varvalue)) ||
                            parseInt(varvalue) < 0 ||
                            parseInt(varvalue) > 100
                        ) {
                            apiLastError = '405';
                            return 'false';
                        }
                        ApiMethods.updateData(varname, varvalue);
                        apiLastError = '0';
                        return 'true';
                    case 'cmi.progress_measure':
                        ApiMethods.updateData(varname, varvalue);
                        return 'true';
                    case 'cmi.core.exit':
                        upperCaseExit = varvalue.toUpperCase();
                        if (
                            upperCaseExit !== 'TIME-OUT' &&
                            upperCaseExit !== 'SUSPEND' &&
                            upperCaseExit !== 'LOGOUT' &&
                            upperCaseExit !== ''
                        ) {
                            apiLastError = '405';
                            return 'false';
                        }
                        ApiMethods.updateData(varname, varvalue);
                        apiLastError = '0';
                        return 'true';
                    case 'cmi.core.session_time':
                        if (!re.test(varvalue)) {
                            apiLastError = '405';
                            return 'false';
                        }
                        timeArray = varvalue.split(':');
                        if (
                            parseInt(timeArray[1]) < 0 ||
                            parseInt(timeArray[1]) >= 60 ||
                            parseInt(timeArray[2]) < 0 ||
                            parseInt(timeArray[2]) >= 60
                        ) {
                            apiLastError = '405';
                            return 'false';
                        }
                        ApiMethods.updateData(varname, varvalue);
                        apiLastError = '0';
                        return 'true';
                    case 'cmi.suspend_data':
                        if (varname.length > 4096) {
                            apiLastError = '405';
                            return 'false';
                        }
                        ApiMethods.updateData(varname, varvalue);
                        apiLastError = '0';
                        return 'true';
                    default:
                        apiLastError = '401';
                        return 'false';
                }
            } else {
                apiLastError = '301';
                return 'false';
            }
        },

        LMSCommit: (commitInput: string): string => {
            const modId = moduleId;
            displayLog('LMSCommit: ' + commitInput);
            if (apiInitialized) {
                if (commitInput !== '') {
                    apiLastError = '201';
                    return 'false';
                } else {
                    apiLastError = '0';
                    commitData(modId, ApiMethods.scormData);
                    return 'true';
                }
            } else {
                apiLastError = '301';
                return 'false';
            }
        },

        LMSFinish: (finishInput: string): string => {
            const modId = moduleId;
            displayLog('LMSFinish: ' + finishInput);
            if (apiInitialized) {
                if (finishInput !== '') {
                    apiLastError = '201';
                    return 'false';
                }
                apiLastError = '0';
                apiInitialized = false;
                const totalTime = secondsToTime(
                    timeToSeconds(
                        ApiMethods.scormData?.['cmi.core.total_time'],
                    ) +
                        timeToSeconds(
                            ApiMethods.scormData?.['cmi.core.session_time'],
                        ),
                );
                ApiMethods.updateData('cmi.core.total_time', totalTime);
                if (
                    ApiMethods?.scormData['cmi.core.exit'].toUpperCase() ===
                    'SUSPEND'
                ) {
                    ApiMethods.scormData['cmi.core.entry'] = 'resume';
                } else {
                    ApiMethods.scormData['cmi.core.entry'] = '';
                }
                commitData(modId, ApiMethods.scormData);
                resetModule();
                return 'true';
            } else {
                apiLastError = '301';
                resetModule();
                return 'false';
            }
        },

        LMSGetLastError: (): string => {
            displayLog('LMSGetLastError: ');
            return apiLastError;
        },

        LMSGetDiagnostic: (errorCode: string): string => {
            const scorm12Errors: any = {
                '0': 'No error',
                '101': 'General Exception',
                '201': 'Invalid Argument Error',
                '202': 'Element cannot have children',
                '203': 'Element not an array.  Cannot have count',
                '301': 'Not initialized',
                '401': 'Not implemented error',
                '402': 'Invalid set value, element is a keyword',
                '403': 'Element is read only',
                '404': 'Element is write only',
                '405': 'Incorrect Data Type',
            };
            displayLog('LMSGetDiagnostic: ' + errorCode);
            let error = 'Unknown Error';
            let code: string = errorCode;
            if (code === '') {
                code = apiLastError;
            }
            switch (code) {
                case '0':
                case '101':
                case '201':
                case '202':
                case '203':
                case '301':
                case '401':
                case '402':
                case '403':
                case '404':
                case '405':
                    error = scorm12Errors[errorCode];
                    break;
            }
            return error;
        },

        LMSGetErrorString: (errorCode: string): string => {
            const scorm12Errors = {
                '0': 'No error',
                '101': 'General Exception',
                '201': 'Invalid Argument Error',
                '202': 'Element cannot have children',
                '203': 'Element not an array.  Cannot have count',
                '301': 'Not initialized',
                '401': 'Not implemented error',
                '402': 'Invalid set value, element is a keyword',
                '403': 'Element is read only',
                '404': 'Element is write only',
                '405': 'Incorrect Data Type',
            };
            displayLog('LMSGetErrorString: ' + errorCode);
            let error = 'Unknown Error';
            switch (errorCode) {
                case '0':
                case '101':
                case '201':
                case '202':
                case '203':
                case '301':
                case '401':
                case '402':
                case '403':
                case '404':
                case '405':
                    error = scorm12Errors[errorCode];
                    break;
            }
            return error;
        },
    };

    useEffect(() => {
        const fetchAndUnzip = async () => {
            try {
                const response = await fetch(scormUrl);
                if (!response.ok)
                    throw new Error('Failed to fetch SCORM package');
                const zipBlob: any = await response.blob();
                const files = await unzipFiles(zipBlob);
                setUnzippedFiles(files);

                const firstHtmlFile = Object.keys(files).find((file) =>
                    file.endsWith('.html'),
                );
                if (firstHtmlFile) {
                    const basePath = firstHtmlFile.substring(
                        0,
                        firstHtmlFile.lastIndexOf('/') + 1,
                    );

                    // Check if basePath is valid
                    const isValidBasePath = Object.keys(files).some((file) =>
                        file.startsWith(basePath),
                    );
                    if (!isValidBasePath) {
                        console.error('Invalid base path:', basePath);
                        alert(
                            'Error: Invalid base path, cannot load SCORM content',
                        );
                        return;
                    }

                    const modifiedHtml = files[firstHtmlFile]
                        .replace(/<head>/i, `<head><base href="${basePath}">`)
                        .replace(/<html/i, `<html style="overflow: hidden"`);
                    setHtmlContent(modifiedHtml);
                }

                const manifestFile = files['imsmanifest.xml'];
                if (!manifestFile) {
                    alert('Invalid SCORM package: imsmanifest.xml not found');
                    return;
                }

                const manifestText = manifestFile;
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(manifestText, 'text/xml');

                const items: CourseItem[] = Array.from(
                    xmlDoc.querySelectorAll('organization item'),
                ).map((item) => {
                    const resourceId = item.getAttribute('identifierref');
                    const resourceElement = resourceId
                        ? xmlDoc.querySelector(
                              `resource[identifier='${resourceId}']`,
                          )
                        : null;
                    const launchPath = resourceElement
                        ? resourceElement.getAttribute('href') || undefined
                        : undefined;
                    return {
                        id: item.getAttribute('identifier') || 'Unknown ID',
                        title:
                            item.querySelector('title')?.textContent ||
                            'Untitled Item',
                        launchUrl: launchPath,
                    };
                });

                const firstLaunchItem = items.find(
                    (item) => item.launchUrl,
                )?.launchUrl;
                if (firstLaunchItem && files[firstLaunchItem]) {
                    const basePath = firstLaunchItem.substring(
                        0,
                        firstLaunchItem.lastIndexOf('/') + 1,
                    );

                    // Check if basePath is valid
                    const isValidBasePath = Object.keys(files).some((file) =>
                        file.startsWith(basePath),
                    );
                    if (!isValidBasePath) {
                        console.error(
                            'Invalid base path for launch item:',
                            basePath,
                        );
                        alert(
                            'Error: Invalid base path for launch item, cannot load SCORM content',
                        );
                        return;
                    }

                    const modifiedHtml = files[firstLaunchItem]
                        .replace(/<head>/i, `<head><base href="${basePath}">`)
                        .replace(/<html/i, `<html style="overflow: hidden"`);
                    setHtmlContent(modifiedHtml);
                }
            } catch (error) {
                console.error('Error loading SCORM package:', error);
            }
        };

        fetchAndUnzip();
    }, [scormUrl]);

    useEffect(() => {
        if (iframeRef.current && contentInjected) {
            const iframe = iframeRef.current;
            const doc = iframe.contentWindow?.document;
            if (doc) {
                doc.open();
                doc.write(htmlContent);
                doc.close();

                // Attach files to the iframe's window
                if (iframe.contentWindow) {
                    iframe.contentWindow.files = unzippedFiles;
                    iframe.contentWindow.API = ApiMethods;

                    // Load configuration files from the rxd folder
                    let rxdConfig: Record<string, any> = {};
                    Object.keys(unzippedFiles)
                        .filter(
                            (filePath) =>
                                filePath.startsWith('rxd/') &&
                                (filePath.endsWith('.json') ||
                                    filePath.endsWith('.js')),
                        )
                        .forEach((filePath) => {
                            if (filePath.endsWith('.json')) {
                                try {
                                    const config = JSON.parse(
                                        unzippedFiles[filePath],
                                    );
                                    rxdConfig.courseTitle = config.courseTitle;
                                    rxdConfig.rxdHostUrl = config.rxdHostUrl;
                                    rxdConfig.contentApi = config.contentApi;
                                    rxdConfig.preLaunchConfigurationUrl =
                                        config.preLaunchConfigurationUrl;
                                } catch (error) {
                                    console.error(
                                        `Error parsing ${filePath}:`,
                                        error,
                                    );
                                }
                            } else if (filePath.endsWith('.js')) {
                                try {
                                    const scriptContent =
                                        unzippedFiles[filePath];
                                    let extractedConfig: any = {};

                                    // Use a function to safely extract RXDConfig
                                    // eslint-disable-next-line @typescript-eslint/no-implied-eval
                                    const extractConfig = new Function(
                                        scriptContent + '; return RXDConfig;',
                                    );
                                    extractedConfig = extractConfig();

                                    if (extractedConfig) {
                                        Object.assign(
                                            rxdConfig,
                                            extractedConfig,
                                        );
                                    }
                                    if (unzippedFiles?.[filePath]?.RXDConfig) {
                                        rxdConfig = unzippedFiles[filePath];

                                        const script =
                                            doc.createElement('script');
                                        script.type = 'text/javascript';
                                        script.textContent =
                                            unzippedFiles[filePath];
                                        doc.body?.appendChild(script);
                                    }
                                } catch (error) {
                                    console.error(
                                        `Error loading ${filePath}:`,
                                        error,
                                    );
                                }
                            }
                        });
                    iframe.contentWindow.RXDConfig = rxdConfig;

                    // Set rxdHostUrl if available
                    if (rxdConfig?.rxdHostUrl) {
                        iframe.contentWindow.rxdHostUrl = rxdConfig?.rxdHostUrl;
                    } else {
                        console.error('rxdHostUrl is invalid or missing');
                        alert(
                            'Error loading configuration settings (rxdHostUrl invalid), launch cannot continue',
                        );
                        return;
                    }
                }

                // Load JavaScript files separately
                Object.keys(unzippedFiles).forEach((filePath) => {
                    if (
                        filePath.endsWith('.js') &&
                        !filePath.includes('rxd/configuration.js')
                    ) {
                        const script = doc.createElement('script');
                        script.type = 'text/javascript';
                        script.textContent = unzippedFiles[filePath];
                        doc.body?.appendChild(script);
                    }
                });

                const script = doc.createElement('script');
                script.type = 'text/javascript';
                script.textContent = `window.addEventListener('message', function(event) {
                    ApiMethods.scormData = event.data;
                })`;
                doc.body?.appendChild(script);
            }
        }
    }, [contentInjected, htmlContent, unzippedFiles]);

    useEffect(() => {
        if (htmlContent) {
            // Create a temporary DOM element to check for the presence of the app-bar element
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = htmlContent;

            const specificElement = tempDiv.querySelector('app-bar');
            if (!specificElement) {
                setContentInjected(true);
            } else {
                console.error('app-bar element is present in the HTML content');
            }
        }
    }, [htmlContent]);

    return (
        <div
            style={{
                height: 'calc(100vh - 90px)',
                width: 'calc(100vw - 20px)',
            }}
        >
            {!htmlContent || !contentInjected || loading ? (
                <Loading />
            ) : (
                <>
                    <iframe
                        id="iframeID"
                        ref={iframeRef}
                        title="HTML Content"
                        sandbox="allow-scripts allow-forms allow-modals allow-same-origin allow-popups"
                        style={{
                            display: !contentInjected ? 'none' : 'block',
                            width: '100%',
                            height: 'calc(100% - 10px)',
                            border: '1px solid #ccc',
                        }}
                    />
                </>
            )}
        </div>
    );
};

export default ScormWrapper;
