import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
    Button,
    Card,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import { useGlobalStore } from '../utilities/GlobalState';
import { Equipment, GlobalState, Module } from '../interfaces/Interfaces';
import { ModulePreviewCard } from '../modules/ModulePreviewCard';
import { AuthPost } from '../utilities/Routing/Requests';
import { toast } from 'react-toastify';
import { Datagrid } from '../utilities/Datagrid';
import { DateTime } from 'luxon';
import { formatDateWithTime2 } from '../utilities/Utilites';
import AddIcon from '@mui/icons-material/Add';
import SliderSwitch from '../utilities/SliderSwitch';
import { ModulePreviewDetails } from './ModulePreviewDetails';
import { DatePicker } from '@mui/x-date-pickers';

export interface Props {
    open: boolean;
    modules: Array<Module>;
    classID: number;
    handleClose: (open?: boolean) => void;
    equipmentList: Array<Equipment>;
}

export const AddModuleModal = ({
    open,
    handleClose,
    modules,
    classID,
    equipmentList,
}: Props) => {
    const globalState = useGlobalStore((state: GlobalState) => state);
    const [localModules, setLocalModules] =
        React.useState<Array<Module>>(modules);
    const [showModuleDetails, setShowModuleDetails] = React.useState<Module>();
    const [cardView, setCardView] = React.useState<boolean>(true);
    const [startDate, setStartDate] = React.useState<any>(DateTime.now());
    const [endDate, setEndDate] = React.useState<any>(DateTime.now());
    const [filterType, setFilterType] = React.useState('title');
    const [equipmentFilter, setEquipmentFilter] = React.useState('');

    const handleSubmit = (
        event: React.FormEvent<HTMLFormElement>,
        moduleID?: number,
    ) => {
        event.preventDefault();

        AuthPost(
            '/classdetail/addclassdetail',
            {
                moduleID: moduleID,
                classID: classID,
                startDate: DateTime.fromJSDate(new Date(startDate)).toString(),
                endDate: DateTime.fromJSDate(new Date(endDate)).toString(),
            },
            {
                'x-access-token': globalState.authToken,
            },
        )
            .then((res) => {
                if (res?.status === 200) {
                    toast.success('Class Successfully Updated!');
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    const onInputChange = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const searchTerm = e.target.value;
        const newModules = modules.filter((m: any) => {
            return (m[filterType] as any)
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
        });
        await setLocalModules(newModules);
    };

    const handleTypeChange = (event: SelectChangeEvent) => {
        setFilterType(event.target.value as string);
    };

    const handleEquipmentChange = (event: SelectChangeEvent) => {
        setEquipmentFilter(event.target.value as string);
        const newModules = modules.filter((module: any) => {
            const formattedIDs = module?.equipment?.split(',');
            return formattedIDs?.includes(event?.target?.value?.toString());
        });
        setLocalModules(newModules);
    };

    return (
        <Modal
            open={open}
            onClose={() => handleClose(false)}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                outline: 'none',
            }}
        >
            <Card
                sx={{
                    width: '90%',
                    margin: '10px',
                    outline: 'none',
                    overflow: 'auto',
                }}
            >
                {showModuleDetails ? (
                    <>
                        <ModulePreviewDetails
                            module={showModuleDetails}
                            toggleModuleDetails={() => {
                                setShowModuleDetails(undefined);
                            }}
                            addModuleButton={(modID) => {
                                return (
                                    <>
                                        <Grid
                                            container
                                            sx={{
                                                padding: '10px',
                                                borderRadius: '20px',
                                                boxShadow:
                                                    'rgb(0, 0, 0) 0px 2px 11px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
                                            }}
                                        >
                                            <Grid
                                                xs={5}
                                                sx={{
                                                    textAlignLast: 'center',
                                                    margin: 'auto',
                                                }}
                                            >
                                                <DatePicker
                                                    label="Start Date"
                                                    value={
                                                        startDate ||
                                                        DateTime.now()
                                                    }
                                                    sx={{ width: '100%' }}
                                                    onChange={(
                                                        newValue: any,
                                                    ) => {
                                                        setStartDate(newValue);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                xs={5}
                                                sx={{
                                                    textAlignLast: 'center',
                                                    margin: 'auto',
                                                }}
                                            >
                                                <DatePicker
                                                    label="Due Date"
                                                    value={
                                                        endDate ||
                                                        DateTime.now()
                                                    }
                                                    onChange={(
                                                        newValue: any,
                                                    ) => {
                                                        setEndDate(newValue);
                                                    }}
                                                    sx={{ width: '100%' }}
                                                />
                                            </Grid>
                                            <Grid
                                                xs={2}
                                                sx={{
                                                    textAlignLast: 'center',
                                                    margin: 'auto',
                                                }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    sx={{ margin: '16px' }}
                                                    onClick={(e: any) => {
                                                        handleSubmit(e, modID);
                                                        setShowModuleDetails(
                                                            undefined,
                                                        );
                                                    }}
                                                >
                                                    ADD MODULE
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </>
                                );
                            }}
                            equipmentList={equipmentList}
                        />
                    </>
                ) : (
                    <Box
                        sx={{
                            margin: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Grid
                            container
                            sx={{ justifyContent: 'space-between' }}
                        >
                            <Grid xs={4}></Grid>
                            <Grid
                                xs={4}
                                sx={{
                                    textAlignLast: 'center',
                                }}
                            >
                                <Typography component="h1" variant="h5">
                                    Available Modules
                                </Typography>
                            </Grid>
                            <Grid
                                xs={4}
                                sx={{
                                    textAlignLast: 'right',
                                }}
                            >
                                Card
                                <SliderSwitch
                                    sx={{ m: 1 }}
                                    value={cardView}
                                    onChange={() => setCardView(!cardView)}
                                />
                                Table
                            </Grid>
                        </Grid>
                        <div
                            style={{
                                width: '100%',
                                margin: '20px',
                            }}
                        >
                            <Grid
                                container
                                sx={{ justifyContent: 'space-between' }}
                            >
                                <Grid xs={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="filter-select-type-label">
                                            Type
                                        </InputLabel>
                                        <Select
                                            labelId="filter-select-type"
                                            id="filter-select-type"
                                            value={filterType}
                                            label="Filter"
                                            onChange={handleTypeChange}
                                        >
                                            <MenuItem value={'title'}>
                                                Title
                                            </MenuItem>
                                            <MenuItem value={'code'}>
                                                Code
                                            </MenuItem>
                                            <MenuItem value={'description'}>
                                                Description
                                            </MenuItem>
                                            <MenuItem value={'concepts'}>
                                                Concepts
                                            </MenuItem>
                                            <MenuItem value={'summary'}>
                                                Summary
                                            </MenuItem>
                                            <MenuItem value={'certifications'}>
                                                Certifications
                                            </MenuItem>
                                            <MenuItem value={'equipment'}>
                                                Equipment
                                            </MenuItem>
                                            <MenuItem value={'outcomes'}>
                                                Outcomes
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={10}>
                                    {filterType === 'equipment' ? (
                                        <FormControl fullWidth>
                                            <InputLabel id={'select-equipment'}>
                                                Equipment
                                            </InputLabel>
                                            <Select
                                                labelId="select-equipment"
                                                id="select-equipment-menu"
                                                value={equipmentFilter}
                                                label="Equipment"
                                                onChange={handleEquipmentChange}
                                                sx={{
                                                    width: '100%',
                                                }}
                                            >
                                                {equipmentList?.map(
                                                    (equipment: any) => {
                                                        return (
                                                            <MenuItem
                                                                key={
                                                                    equipment?.id
                                                                }
                                                                value={
                                                                    equipment?.id
                                                                }
                                                            >
                                                                {
                                                                    equipment?.title
                                                                }
                                                            </MenuItem>
                                                        );
                                                    },
                                                )}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        <TextField
                                            // fullWidth
                                            name="search"
                                            label="Module Filter"
                                            type="text"
                                            sx={{
                                                width: '100%',
                                                // padding: '20px',
                                            }}
                                            onChange={onInputChange}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                        <Grid
                            container
                            sx={{ justifyContent: 'space-between' }}
                        >
                            {cardView ? (
                                localModules.map((module) => {
                                    return (
                                        <div
                                            key={module.id}
                                            onClick={() =>
                                                setShowModuleDetails(module)
                                            }
                                            //   onDoubleClick={() =>
                                            //       setShowModuleDetails(module)
                                            //   }
                                            //   className={
                                            //       selectedModule === module.id
                                            //           ? 'Border-Selected-500'
                                            //           : ''
                                            //   }
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                marginBottom: '20px',
                                            }}
                                        >
                                            <ModulePreviewCard
                                                key={module.id}
                                                handleToggleModulePage={() => {
                                                    return;
                                                }}
                                                module={module}
                                            />
                                        </div>
                                    );
                                })
                            ) : (
                                <Datagrid
                                    sx={{ height: 'unset' }}
                                    editMode="row"
                                    //   onRowClick: (params: any) =>
                                    //       setSelectedModule(params.row.id),
                                    columns={[
                                        {
                                            field: 'id',
                                            headerName: 'ID',
                                            type: 'string',
                                            flex: 0.25,
                                        },
                                        {
                                            field: 'title',
                                            headerName: 'Title',
                                            type: 'string',
                                            flex: 1,
                                        },
                                        {
                                            field: 'description',
                                            headerName: 'Description',
                                            type: 'string',
                                            flex: 1,
                                        },
                                        {
                                            field: 'publishDate',
                                            headerName: 'Publish Date',
                                            type: 'string',
                                            flex: 1,
                                            renderCell: (rowData) => {
                                                const date = DateTime.fromISO(
                                                    rowData.row
                                                        .publishDate as any,
                                                ) as any;
                                                return rowData?.row?.publishDate
                                                    ? formatDateWithTime2(date)
                                                    : 'Unpublished';
                                            },
                                        },
                                        {
                                            field: 'action',
                                            headerName: 'Action',
                                            flex: 1,
                                            sortable: false,
                                            renderCell: (rowData) => {
                                                return (
                                                    <>
                                                        <IconButton
                                                            sx={{
                                                                marginLeft:
                                                                    '5px',
                                                                backgroundColor:
                                                                    '#f1c40f',
                                                                border: 'solid #fff 1px',
                                                                color: '#fff',
                                                                fontWeight:
                                                                    'bold',
                                                                fontSize:
                                                                    '20px',
                                                            }}
                                                            title="Add"
                                                            onClick={() => {
                                                                return AuthPost(
                                                                    '/classdetail/addclassdetail',
                                                                    {
                                                                        moduleID:
                                                                            rowData
                                                                                ?.row
                                                                                ?.id,
                                                                        classID:
                                                                            classID,
                                                                    },
                                                                    {
                                                                        'x-access-token':
                                                                            globalState.authToken,
                                                                    },
                                                                )
                                                                    .then(
                                                                        (
                                                                            res,
                                                                        ) => {
                                                                            if (
                                                                                res?.status ===
                                                                                200
                                                                            ) {
                                                                                toast.success(
                                                                                    'Class Successfully Updated!',
                                                                                );
                                                                                handleClose(
                                                                                    true,
                                                                                );
                                                                            }
                                                                        },
                                                                    )
                                                                    .catch(
                                                                        (
                                                                            err,
                                                                        ) => {
                                                                            toast.error(
                                                                                err.toString(),
                                                                            );
                                                                        },
                                                                    );
                                                            }}
                                                        >
                                                            <AddIcon />
                                                        </IconButton>
                                                    </>
                                                );
                                            },
                                        },
                                    ]}
                                    data={modules}
                                />
                            )}
                        </Grid>
                    </Box>
                )}
            </Card>
        </Modal>
    );
};
